import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';
import { baseUrlAlertas, } from '../../assets/shared/urlApps';

const initialState = {
  usuarios: {
    loading: false,
    usuarios: [],
    error: null,
    estaCargado: false
  }
};

export const loadUsuarios = createAsyncThunk(
  'clientes/loadUsuarios',
  async (idCliente) => {
    const response = await axios.get(`${baseUrlAlertas()}api/cliente/${idCliente}/usuarios`);
    return response.data;
  })

export const clientesSlice = createSlice({
  name: 'clientes',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUsuarios.pending, (state) => {
        state.usuarios.loading = true;
        state.usuarios.usuarios = [];
        state.usuarios.estaCargado = false;
        state.usuarios.error = null;
      })
      .addCase(loadUsuarios.fulfilled, (state, response) => {
        state.usuarios.loading = false;
        state.usuarios.usuarios = response.payload;
        state.usuarios.estaCargado = true;
        state.usuarios.error = null;
      })
      .addCase(loadUsuarios.rejected, (state, error) => {
        state.usuarios.loading = false;
        state.usuarios.usuarios = [];
        state.usuarios.estaCargado = true;
        state.usuarios.error = error;
      })
  }
});

export default clientesSlice.reducer;