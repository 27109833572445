import { createSlice } from '@reduxjs/toolkit';

const initialState = {  
  ejecucionAdministracionAlertas: false,
  ejecucionAdministracionInformes: false,
  paginaRender: "/",
};

export const ejecucionPaginasSlice = createSlice({
  name: 'ejecucionPaginas',
  initialState,
  reducers: {
    cambioPaginaRender: {
      reducer: (state, action) => {
        state.paginaRender = action.payload.page;
      },
      prepare: (page) => {
        return {
          payload: {
            page,
          }
        };
      }
    },
    ejecutarAlertas: (state) => {
      state.ejecucionAdministracionAlertas = true;
    },
    ejecutarInformes: (state) => {
      state.ejecucionAdministracionInformes = true;
    }
  }
});

export const { cambioPaginaRender, ejecutarAlertas, ejecutarInformes } = ejecucionPaginasSlice.actions;

export default ejecucionPaginasSlice.reducer;