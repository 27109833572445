import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Divider } from '@mui/material';

const dialog = (props) => {
  const handleClose = () => {
    props.onClose();
  };

  const styleDefault = {
    margin: '0px',
    padding: '15px',
    backgroundColor: props.backgroundColor
  };

  return (
    <Dialog
      fullWidth={props.fullWidth}
      fullScreen={props.fullScreen}
      maxWidth={props.maxWidth ? props.maxWidth : false}
      open={props.show}
      onClose={handleClose}
      style={{
        backdropFilter: 'blur(3px)'
      }}
    >
      {props.title && (
        <DialogTitle
          style={{
            padding: '0px',
            marginTop: '5px',
            paddingLeft: '10px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            backgroundColor: props.backgroundColor
          }}
        >
          {props.title}
        </DialogTitle>
      )}
      <Divider />
      <DialogContent
        style={props.styleContent ? props.styleContent : styleDefault}>
        {props.body}
      </DialogContent>
      <Divider />
      {props.actions && <DialogActions>{props.actions}</DialogActions>}
    </Dialog>
  );
};

export default dialog;