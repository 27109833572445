import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken, storageUsuarioId } from "../../assets/shared/sessionData";
import axios from '../../axios-alertas';
import { enqueueSnackbar } from "./snackbarSlice";
import { baseUrlAlertas } from "../../assets/shared/urlApps";

const initialState = {};

export const enviarGraficosPorMail = createAsyncThunk(
  'notificaciones/enviarGraficosPorMail',
  async ({ data, success }, { dispatch }) => {
    axios
      .post(`/notificaciones/EnviarGraficosPorMail`, data)
      .then((response) => {
        dispatch(
          enqueueSnackbar({
            message: `Gráficos enviados por mail con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
          })
        );

        return success && success();
      })
      .catch((error) => {
        let errorMessage = `Error al enviar los gráficos por mail, intente nuevamente.`;
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      });
  }
);

export const enviarNotificacionInterna = createAsyncThunk(
  'notificaciones/enviarNotificacionInterna',
  async ({ notificacion, onSuccess }, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.post(`${baseUrlAlertas()}notificacion/enviarComunicacionInterna?idUsuario=${idUsuario}`, notificacion, requestOptions)
      .then(() => dispatch(
        enqueueSnackbar({
          message: `Notificación enviada con éxito!`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      ))
      .then(() => {
        if (onSuccess)
          onSuccess();
      })
      .catch((error) => {
        let errorMessage = `Error al enviar la notificación, intente nuevamente.`;
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      });
  }
)

export const notificacionesSlice = createSlice({
  name: 'notificaciones',
  initialState,
  reducers: {

  }
});

export default notificacionesSlice.reducer;