import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@mui/styles";
import MultipleEmails from "../MultipleEmails/MultipleEmails";
import { Grid, TextField } from "@mui/material";
import Buton from "../Button/Button";
import SendIcon from '@mui/icons-material/Send';
import * as htmlToImage from 'html-to-image';
import Spinner from "../../UI/Spinner/Spinner";

import { enviarGraficosPorMail } from "../../../store/slices/notificacionesSlice";
import { showSendChartModal } from "../../../store/slices/modalsSlice";

const useStyles = makeStyles({
  subject: {
    width: '100%',
    fontSize: 'small',
  },
  message: {
    width: '100%',
    fontSize: 'small',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  spinnerItems: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  graficos: {
    minWidth: 'fit-content'
  }
});

const EnviarChartEmailModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const chartRef = useSelector(state => state.modals.sendChartModal.chartRef);

  const userEmail = sessionStorage.getItem("userEmail");
  const [chartHTML, setChartHTML] = useState(null);
  const [requiredMessage] = useState("Campo requerido");
  const [emails, setEmails] = useState([userEmail]);
  const [subject, setSubject] = useState(props.tituloChart !== '' ? `Enviar gráfico - ${props.tituloChart}` : 'Enviar gráfico');
  const [message, setMessage] = useState("");
  const [errorEmails, setErrorEmails] = useState(false);
  const [errorSubject, setErrorSubject] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [sending, setSending] = useState(false);

  const isvalid = (item) => {
    if (item === null || item === undefined) {
      return false;
    } else {
      if (typeof item === 'string' || item instanceof String) {
        const itemTrim = item.trim();

        if (itemTrim === "") {
          return false;
        }
      }
    }

    return true;
  }

  const removeNodesFromChart = useCallback((stringContent) => {
    const el = document.createElement('div');
    el.innerHTML = stringContent;
    const toolBars = el.getElementsByClassName('apexcharts-toolbar');

    for (let toolBar of toolBars) {
      toolBar.remove();
    }

    return el.outerHTML;
  }, []);

  useEffect(() => {
    if (chartHTML === null && chartRef !== null && chartRef.current !== null && chartRef.current !== undefined) {
      let tempHTML = removeNodesFromChart(chartRef.current.outerHTML);
      setChartHTML(tempHTML);
    }
  }, [chartHTML, chartRef, removeNodesFromChart])

  const onEmailsChange = useCallback((items) => {
    setEmails(items);
    setErrorEmails(items === null || items.length === 0);
  }, []);

  const onSubjectChange = useCallback((evt) => {
    const value = evt.target.value;
    setErrorSubject(!isvalid(value));
    setSubject(value);
  }, []);

  const onMessageChange = useCallback((evt) => {
    const value = evt.target.value;
    setErrorMessage(!isvalid(value));
    setMessage(value);
  }, []);

  const showChart = () => {
    return (
      <Fragment>
        <div dangerouslySetInnerHTML={{ __html: chartHTML }} />
      </Fragment>
    )
  }

  const onSendChartSuccess = () => {
    closeChartModal();
    setTimeout(() => setSending(false), 500);
  }

  const onSendChart = () => {
    setSending(true);
    setErrorSubject(!isvalid(subject));
    setErrorMessage(!isvalid(message));
    setErrorEmails(emails === null || emails.length === 0);

    if (!errorEmails && !errorSubject && !errorMessage) {
      const element = document.createElement('div');
      element.innerHTML = chartHTML;

      sendImage(chartRef.current);
    }
  }

  const sendImage = (element) => {
    htmlToImage.toPng(element)
      .then(function (dataUrl) {
        const data = {
          destinatarios: emails,
          asunto: subject,
          mensaje: message,
          graficos: [
            { filename: 'chart.png', content: dataUrl, type: 'image/png' }],
        };

        enviarGraficos(data, onSendChartSuccess);
      })
      .catch(function (error) {
        console.error('Error al enviar los gráficos por mail, intente nuevamente.', error);
        setTimeout(() => setSending(false), 500);
      });
  }
  
  const enviarGraficos = (data, success) => dispatch(enviarGraficosPorMail(data, success));
  const closeChartModal = () => dispatch(showSendChartModal(false, null));

  return (
    <Grid container spacing={1} >
      <Grid item xs={12} sm={12} md={12}>
        <MultipleEmails
          items={emails}
          setItems={onEmailsChange}
          errorItems={errorEmails}
          errorItemsMessage={requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          label="Asunto"
          variant="outlined"
          size="small"
          className={classes.subject}
          value={subject}
          onChange={(e) => onSubjectChange(e)}
          required={true}
          error={errorSubject}
          helperText={errorSubject && requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          label="Mensaje"
          variant="outlined"
          size="small"
          className={classes.subject}
          value={message}
          multiline
          rows={4}
          onChange={(e) => onMessageChange(e)}
          required={true}
          inputProps={{
            maxLength: 500,
          }}
          error={errorMessage}
          helperText={errorSubject && requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid
        item xs={12} sm={12} md={12}
        container
        className={classes.buttonContainer}
      >
        <Grid item xs={6} sm={4} md={3}>
          <Buton
            style={{ width: '95%', padding: "4px" }}
            color="primary"
            startIcon={<SendIcon />}
            onClick={onSendChart}
            disabled={sending}
          >
            Enviar
          </Buton>
        </Grid>
      </Grid>
      <Grid
        item xs={12} sm={12} md={12}
        className={classes.graficos}
      >
        {sending
          ? (
            <div className={classes.spinnerItems}>
              <Spinner personalSize="75px" />
            </div>
          ) : showChart()}
      </Grid>
    </Grid>
  )
}

export default EnviarChartEmailModal;