import React, { useEffect, useState } from 'react';
import "./InformeFrecuencia.module.css";

const InformeFrecuencia = (props) => {
  const { cronExpression, setCronExpression, canEdit, setShowFrecuencia } = props;
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const daysMap = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

  useEffect(() => {
    if (cronExpression && selectedFrequency === '') {
      let cronSplit = cronExpression.split(' ');

      if (cronSplit && cronSplit.length === 6) {
        cronSplit = cronSplit.slice(1);
      }

      if (cronSplit[0] !== '*') {
        setSelectedFrequency(cronSplit[2] === '1' ? 'Mensual' : 'Semanal');

        if (cronSplit[4]) {
          const days = cronSplit[4].split(',');

          if (days && days.length > 0) {
            let tempDays = [];

            if (days[0] === '*') {
              tempDays = [1, 2, 3, 4, 5, 6, 7];
            } else {
              days.forEach(day => {
                tempDays.push(Number(day));
              });
            }

            setSelectedDays(tempDays);
          }
        }

        if (cronSplit[0] !== '*' && cronSplit[1] !== "*") {
          const time = `${cronSplit[1]}:${cronSplit[0]}`;
          setSelectedTime(time);
        }
      }
    }
  }, [cronExpression, selectedFrequency]);

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handleFrequencyChange = (frequency) => {
    setSelectedFrequency(frequency);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const validateForm = () => {
    if (!selectedFrequency) {
      setErrorMessage("Por favor, selecciona una frecuencia (Semanal, Quincenal o Mensual).");
      return false;
    }

    if (selectedDays.length === 0) {
      setErrorMessage("Por favor, selecciona al menos un día de la semana.");
      return false;
    }

    if (!selectedTime) {
      setErrorMessage("Por favor, selecciona un horario.");
      return false;
    }

    setErrorMessage(""); // Reset error message if all fields are valid
    return true;
  };

  const generateCronExpression = () => {
    const [hour, minute] = selectedTime.split(":").map(Number);
    const days = selectedDays.length > 0 ? selectedDays.join(",") : "*";
    let cronExpression = "";

    switch (selectedFrequency) {
      case "Semanal":
        cronExpression = `${minute} ${hour} * * ${days}`;
        break;
      //case "Quincenal":
      //  cronExpression = `${minute} ${hour} * * ${days} #Quincenal`;
      //  break;
      case "Mensual":
        cronExpression = `${minute} ${hour} 1 * *`;
        break;
      default:
        cronExpression = `${minute} ${hour} * * *`;
        break;
    }

    return cronExpression;
  };

  const getSelectionSummary = () => {
    if (!selectedFrequency && selectedDays.length === 0 && !selectedTime) {
      return (
        <div style={{ marginTop: "10px", fontSize: "14px", color: "#555", minWidth: '500px' }}>
          <strong>Selección actual:</strong> No se ha realizado ninguna selección.
        </div>
      )
    }

    const daysSelected = selectedDays.map((day) => daysMap[day - 1]).join(", ");
    const timeSelected = selectedTime ? `a las ${selectedTime}` : "sin horario definido";

    return (
      <div style={{ marginTop: "10px", fontSize: "14px", color: "#555", minWidth: '500px' }}>
        <strong>Selección actual:</strong><br />
        <span><strong>Frecuencia: </strong>{selectedFrequency || "No definida"}</span><br />
        <span><strong>Días: </strong>{daysSelected || "Ninguno"}</span><br />
        <span><strong>Horario: </strong>{timeSelected}</span>
      </div>
    )
  };

  const handleSave = () => {
    if (!validateForm()) return;

    const cronExpression = generateCronExpression();
    setCronExpression(cronExpression);
    setShowFrecuencia(false);
  };

  const handleCancel = () => {
    setSelectedFrequency('');
    setSelectedDays([]);
    setSelectedTime('');
    setShowFrecuencia(false);
  };

  return (
    <div className="schedule-popup">
      {/* Error Message */}
      {errorMessage && (
        <div className="error-message">
          {errorMessage}
        </div>
      )}

      {/* Frecuencia */}
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {["Semanal", "Mensual"].map((frequency) => (
          <button
            key={frequency}
            disabled={!canEdit}
            onClick={() => handleFrequencyChange(frequency)}
            className={`frequency-button ${selectedFrequency === frequency ? 'selected' : ''}`}
          >
            {frequency}
          </button>
        ))}
      </div>

      {/* Días de la semana en una sola línea */}
      <div className="day-buttons-container">
        {["L", "M", "M", "J", "V", "S", "D"].map((day, index) => (
          <button
            key={index}
            disabled={!canEdit}
            onClick={() => toggleDay(index + 1)}
            className={`day-button ${selectedDays.includes(index + 1) ? 'selected' : ''}`}
          >
            {day}
          </button>
        ))}
      </div>

      {/* Horario */}
      <div>
        <input
          type="time"
          value={selectedTime}
          disabled={!canEdit}
          onChange={handleTimeChange}
          className="time-input"
        />
      </div>

      {/* Resumen de selección */}
      {getSelectionSummary()}

      {/* Botones de acción */}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
        <button
          onClick={handleSave}
          disabled={!canEdit}
          className="action-button action-button-save"
        >
          Guardar
        </button>
        <button
          onClick={handleCancel}
          className="action-button action-button-cancel"
          style={{ marginLeft: '20px' }}
        >
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default InformeFrecuencia;