import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment } from 'react';
import { Typography } from '@mui/material';
import EstadosChequesSelector from '../../UI/EstadosChequesSelector/EstadosChequesSelector';
import Button from '../../UI/Button/Button';
import DeleteableItem from '../../UI/DeleteableItem/DeleteableItem';
import Spinner from '../../UI/Spinner/Spinner';
import styles from './ChequesValorEstado.module.css';
import { compareByAsc } from '../../../assets/shared/sorting';
import { loadChequesValorEstado, loadEstadosCheques, postChequesValorEstado } from '../../../store/slices/configuracionesSlice';

const ChequesValorEstado = (props) => {
  const { onCloseModal } = props;

  const dispatch = useDispatch();

  const onChequesValorEstadoLoad = (propios, success) => dispatch(loadChequesValorEstado({ propios, success }));
  const onLoadEstados = () => dispatch(loadEstadosCheques());
  const onPostValoresEstados = (valoresYEstados, propios, success, error) =>
    dispatch(postChequesValorEstado(valoresYEstados, propios, undefined, success, error));
  const estados = useSelector(state => state.configuraciones.loadEstadosCheques.estados);

  const [valoresEstadosLocal, setValoresEstadosLocal] = useState({
    valoresTerceros: [],
    rechazadosTerceros: [],
    valoresPropios: []
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!estados || estados.length === 0) {
      onLoadEstados();
    }
    setLoading(true);
    onChequesValorEstadoLoad(false, (valoresEstadosTerceros) => {
      let formatValoresEstados = { valoresTerceros: [], rechazadosTerceros: [], valoresPropios: [] };
      formatValoresEstados.valoresTerceros = valoresEstadosTerceros.valores;
      formatValoresEstados.rechazadosTerceros = valoresEstadosTerceros.rechazados || [];
      onChequesValorEstadoLoad(true, (valoresEstadosPropios) => {
        formatValoresEstados.valoresPropios = valoresEstadosPropios.valores || [];
        setValoresEstadosLocal(formatValoresEstados);
        setLoading(false);
      });
    });
  }, []);

  const [addEstadoLoading, setAddEstadoLoading] = useState(false);

  const addValoresEstadosLocal = (index, key, estado) => {
    if (!estado) {
      return;
    }
    setAddEstadoLoading(true);
    valoresEstadosLocal[key][index].estados.push(estado);
    setTimeout(() => {
      setAddEstadoLoading(false);
    }, 200);
  };

  const onSetEstadoSelected = (key, index, estado) => {
    const estadoActual = estadoSelected[key];
    estadoActual[index] = estado;
    setEstadoSelected({ ...estadoSelected, [key]: estadoActual });
  };

  const verifyExistEstado = (key, index, estado) => {

    if (estado) {
      return valoresEstadosLocal[key][index].estados.some((est) => {
        return est.id_estado === estado.id_estado;
      });
    }
  };

  const buildValoresEstadosComponent = (key, title) => {
    return valoresEstadosLocal[key].map((valorEstado, index) => {
      return (
        <div key={index} className={styles.ChequesValorEstadoContainer}>
          {index === 0 && buildTitleAndSubtitle(title)}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" style={{ minWidth: '150px', fontSize: '0.9em' }}>
              {valorEstado.nombre}
            </Typography>
            <EstadosChequesSelector
              onChangeEstado={(estado) => onSetEstadoSelected(key, index, estado)}
              flexChangeEstados={addEstadoLoading}
            />
            <Button
              disabled={verifyExistEstado(key, index, estadoSelected[key][index])}
              size="small"
              style={{ fontSize: 'smaller' }}
              onClick={() => addValoresEstadosLocal(index, key, estadoSelected[key][index])}
            >
              + Agregar
            </Button>
            {buildEstados(key, index)}
            {addEstadoLoading && <Spinner personalSize="10px" />}
          </div>
        </div>
      );
    });
  };

  const buildTitleAndSubtitle = (title) => (
    <Fragment>
      <Typography variant="h5">{title}</Typography>
      <Typography variant="caption">Seleccione los estados correspondientes a cada valor</Typography>
    </Fragment>
  );

  const removeEstado = (key, index, estado) => {
    const removeIndex = valoresEstadosLocal[key][index].estados.findIndex((est) => est.id_estado === estado.id_estado);
    if (removeIndex !== -1) {
      setAddEstadoLoading(true);

      valoresEstadosLocal[key][index].estados.splice(removeIndex, 1);
      setTimeout(() => {
        setAddEstadoLoading(false);
      }, 200);
    }
  };

  const buildEstados = (key, index) => {
    return (
      <div className={styles.EstadosContainer} 
        style={{ display: 'flex', maxWidth: '50rem', flexFlow: 'wrap', rowGap: '4px' }}>
        {valoresEstadosLocal[key][index].estados
          .sort((a, b) => compareByAsc(a, b, 'nombre'))
          .map((estado, estadoIndex) => (
            <DeleteableItem
              key={estadoIndex}
              text={estado.nombre}
              onClick={() => removeEstado(key, index, estado)}
            />
          ))}
      </div>
    );
  };

  const [estadoSelected, setEstadoSelected] = useState({
    valoresTerceros: {},
    rechazadosTerceros: {},
    valoresPropios: {}
  });

  const [loadingPost, setLoadingPost] = useState(false);

  const onGuardarCambiosClick = () => {
    const chequesTerceros = {
      valores: valoresEstadosLocal.valoresTerceros,
      rechazados: valoresEstadosLocal.rechazadosTerceros
    };

    const chequesPropios = {
      valores: valoresEstadosLocal.valoresPropios,
      rechazados: []
    };

    onPostValoresEstados(chequesTerceros, false, () => {
      //post cheques propios
      onPostValoresEstados(chequesPropios, true, () => {
        setLoadingPost(true);
        onCloseModal();
      });
    });
  };

  return loading ? (
    <div style={{ margin: '20px' }}>
      <Spinner />
    </div>
  ) : (
    <div style={{ marginTop: '-20px' }}>
      {buildValoresEstadosComponent('valoresTerceros', 'Cheques terceros', valoresEstadosLocal.valoresTerceros)}
      {buildValoresEstadosComponent('rechazadosTerceros', 'Cheques rechazados', valoresEstadosLocal.rechazadosTerceros)}
      {buildValoresEstadosComponent('valoresPropios', 'Cheques emitidos', valoresEstadosLocal.valoresPropios)}
      <div className={styles.GuardarCambiosContainer}>
        <Button
          size="small"
          style={{ fontSize: 'smaller' }}
          onClick={() => onGuardarCambiosClick()}
          disabled={loadingPost}
        >
          Guardar cambios
        </Button>
      </div>
    </div>
  );
};

export default ChequesValorEstado;