import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalModal: {
    show: false,
    title: "",
    body: "",
    footer: "",
    disableCloseButton: false,
    fullWidth: false,
    maxWidth: "",
    fullScreen: false,
  },
  listFiltersModal: {
    show: false,
    title: "",
    filterTypes: [],
  },
  sendChartModal: {
    show: false,
    chartRef: null,
    tituloChart: "",
  },
  estadoModal: {
    show: false,
    title: "",
    body: "",
  },
  backButtonModal: {
    show: false,
  },
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showGlobalModal: {
      reducer: (state, action) => {
        const { show, title, body, footer, disableCloseButton, fullWidth, fullScreen, maxWidth } = action.payload;
        state.globalModal.show = show;
        state.globalModal.title = title;
        state.globalModal.body = body;
        state.globalModal.footer = footer;
        state.globalModal.disableCloseButton = disableCloseButton;
        state.globalModal.fullWidth = fullWidth;
        state.globalModal.fullScreen = fullScreen;
        state.globalModal.maxWidth = maxWidth;

      },
      prepare: (show, title, body, footer, disableCloseButton, fullWidth, fullScreen, maxWidth) => {
        return {
          payload: {
            show,
            title,
            body,
            footer,
            disableCloseButton,
            fullWidth,
            fullScreen,
            maxWidth
          }
        }
      }
    },
    changeGlobalModalBody: {
      reducer: (state, action) => {
        state.globalModal = action.payload;
      },
      prepare: (body) => {
        return {
          payload: body
        };
      }
    },
    showListFiltersModal: {
      reducer: (state, action) => {
        state.listFiltersModal.show = action.payload.show;
        state.listFiltersModal.title = action.payload.title;
        state.listFiltersModal.filterTypes = action.payload.filterTypes;
      },
      prepare: (show, title, filterTypes) => {
        return { payload: { show, title, filterTypes, } };
      }
    },
    hiddenGlobalModal: (state, action) => {
      state.globalModal = initialState.globalModal;
    },
    showSendChartModal: {
      reducer: (state, action) => {
        state.sendChartModal.show = action.payload.show;
        state.sendChartModal.chartRef = action.payload.chartRef;
        state.sendChartModal.tituloChart = action.payload.show;
      },
      prepare: (show, chartRef, tituloChart) => {
        return {
          payload: {
            show,
            chartRef,
            tituloChart,
          }
        };
      }
    },
    showEstadoModal: {
      reducer: (state, action) => {
        state.estadoModal.show = action.payload.show;
        state.estadoModal.title = action.payload.title;
        state.estadoModal.body = action.payload.body;
      },
      prepare: (show, title, body) => {
        return {
          payload: {
            show,
            title,
            body,
          }
        };
      }
    },
    hiddenEstadoModal: (state) => {
      state.estadoModal = initialState.estadoModal;
    },
    showBackButtonModal: {
      reducer: (state, action) => {
        state.backButtonModal.show = action.payload.show;
      },
      prepare: (show) => {
        return {
          payload: {
            show,
          }
        };
      }
    },
  }
});

export const { showGlobalModal, changeGlobalModalBody, showListFiltersModal, hiddenGlobalModal,
  showSendChartModal, showEstadoModal, hiddenEstadoModal, showBackButtonModal } = modalsSlice.actions;

export default modalsSlice.reducer;

export const listFiltersModal = state => state.listFiltersModal;