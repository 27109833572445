import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken, storageUsuarioId } from "../../assets/shared/sessionData";
import axios from '../../../src/axios-alertas';
import { baseUrlAlertas } from "../../assets/shared/urlApps";

const initialState = {
  alertasDisponibles: {
    loading: false,
    estaCargado: false,
    alertas: [],
    error: null
  },
  alertaDisponibleModal: {
    alerta: undefined,
    show: false,
    type: '',
    title: '',
    subTitle: '',
    maxWidth: 'md',
  },
  ejecutarAlerta: {
    ejecutando: false,
    ejecutada: false,
    error: null
  },
  configuraciones: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
  tiposUI: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
};

export const loadAlertasDisponibles = createAsyncThunk(
  'tipoAlertas/loadAlertasDisponibles',
  async () => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const idUsuario = storageUsuarioId();
    const response = await axios.get(`${baseUrlAlertas()}api/tipoAlerta?idUsuario=${idUsuario}`, requestOptions);
    return response && response.data;
  }
);

export const ejecutarAlerta = createAsyncThunk(
  'tipoAlertas/ejecutarAlerta',
  async (idAlerta, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/alerta/${idAlerta}/ejecutar?idUsuario=${idUsuario}`, {}, requestOptions)
      .then(() => dispatch(loadAlertasDisponibles()));
  }
);

export const actualizarTipoAlerta = createAsyncThunk(
  'tipoAlertas/actualizarTipoAlerta',
  async ({ alerta, onSuccess, onError }, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/tipoAlerta/${alerta.id}/actualizar`, alerta, requestOptions)
      .then(() => {
        dispatch(loadAlertasDisponibles());

        if (onSuccess)
          onSuccess();
      }).catch((error) => {
        if (onError)
          onError(error);
      });
  }
);

export const loadTiposUI = createAsyncThunk(
  'tipoAlertas/loadTiposUI',
  async () => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}api/tipoAlerta/tiposUI`, requestOptions);
    return response && response.data;
  }
);

export const tipoAlertasSlice = createSlice({
  name: 'tipoAlertas',
  initialState,
  reducers: {
    openEditarTipoAlertaModal: (state, action) => {
      state.alertaDisponibleModal.alerta = action.payload;
      state.alertaDisponibleModal.show = true;
      state.alertaDisponibleModal.type = 'update';
      state.alertaDisponibleModal.title = 'Editar tipo de alerta';
      state.alertaDisponibleModal.maxWidth = 'md';
    },
    openAyudaAlertaDisponibleModal: (state, action) => {
      state.alertaDisponibleModal.alerta = action.payload;
      state.alertaDisponibleModal.show = true;
      state.alertaDisponibleModal.type = 'ayuda';
      state.alertaDisponibleModal.title = 'Ayuda';
      state.alertaDisponibleModal.maxWidth = 'md';
    },
    openConfigurarAlertaDisponibleModal: (state, action) => {
      state.alertaDisponibleModal.alerta = action.payload;
      state.alertaDisponibleModal.show = true;
      state.alertaDisponibleModal.type = 'configurar';
      state.alertaDisponibleModal.title = 'Configurar alerta';
      state.alertaDisponibleModal.maxWidth = 'md';
    },
    openActualizarAlertaDisponibleModal: (state, action) => {
      state.alertaDisponibleModal.alerta = action.payload;
      state.alertaDisponibleModal.show = true;
      state.alertaDisponibleModal.type = 'editar';
      state.alertaDisponibleModal.title = 'Editar alerta';
      state.alertaDisponibleModal.maxWidth = 'md';
    },
    closeAlertaDisponibleModal: (state) => {
      state.alertaDisponibleModal.alerta = undefined;
      state.alertaDisponibleModal.show = false;
      state.alertaDisponibleModal.type = '';
      state.alertaDisponibleModal.title = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAlertasDisponibles.pending, (state) => {
        state.alertasDisponibles = {
          loading: true,
          estaCargado: false,
          alertas: [],
          error: null,
        };
      })
      .addCase(loadAlertasDisponibles.fulfilled, (state, action) => {
        state.alertasDisponibles = {
          loading: false,
          estaCargado: true,
          alertas: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadAlertasDisponibles.rejected, (state, error) => {
        state.alertasDisponibles = {
          loading: false,
          estaCargado: true,
          alertas: [],
          error: error,
        };
      })
      .addCase(ejecutarAlerta.pending, (state) => {
        state.ejecutarAlerta = {
          ejecutando: true,
          ejecutada: false,
          error: null,
        };
      })
      .addCase(ejecutarAlerta.fulfilled, (state) => {
        state.ejecutarAlerta = {
          ejecutando: false,
          ejecutada: true,
          error: null,
        };
      })
      .addCase(ejecutarAlerta.rejected, (state, error) => {
        state.ejecutarAlerta = {
          ejecutando: false,
          ejecutada: true,
          error: error
        };
      })
      .addCase(loadTiposUI.pending, (state) => {
        state.tiposUI = {
          loading: true,
          estaCargado: false,
          alertas: [],
          error: null,
        };
      })
      .addCase(loadTiposUI.fulfilled, (state, action) => {
        state.tiposUI = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadTiposUI.rejected, (state, error) => {
        state.tiposUI = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
  }
});

export const { openEditarTipoAlertaModal, openAyudaAlertaDisponibleModal,
  openConfigurarAlertaDisponibleModal, openActualizarAlertaDisponibleModal, closeAlertaDisponibleModal } = tipoAlertasSlice.actions;
export default tipoAlertasSlice.reducer;