import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken, storageUsuarioId } from "../../assets/shared/sessionData";
import axios from '../../../src/axios-alertas';
import { baseUrlAlertas } from "../../assets/shared/urlApps";
import { enqueueSnackbar } from "./snackbarSlice";
import { loadAlertasDisponibles } from "./tipoAlertaSlice";

const initialState = {
  alertasConfiguradas: {
    loading: false,
    estaCargado: false,
    alertas: [],
    error: null
  },
  alertaModal: {
    alerta: undefined,
    show: false,
    type: '',
    title: '',
    subTitle: '',
    maxWidth: 'md',
  },
  detalleAlerta: {
    alerta: undefined,
    loading: false,
    estaCargado: false,
    error: null
  },
  historialAlerta: {
    loading: false,
    estaCargado: false,
    registros: [],
    error: null
  },
  ejecutarAlerta: {
    ejecutando: false,
    ejecutada: false,
    error: null
  },
  eliminarAlerta: {
    ejecutando: false,
    ejecutada: false,
    error: null
  },
  configuraciones: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
};

export const crearAlerta = createAsyncThunk(
  'alertas/crearAlerta',
  async ({ alerta, onSuccess, onError }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.post(`${baseUrlAlertas()}api/alerta/CrearAlerta?idUsuario=${storageUsuarioId()}`, alerta, requestOptions)
      .then(() => {
        if (onSuccess)
          onSuccess();
      }).catch((error) => {
        if (onError)
          onError(error);
      });
  }
);

export const actualizarAlerta = createAsyncThunk(
  'alertas/actualizarAlerta',
  async ({ alerta, onSuccess, onError }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/alerta/${alerta.id}/editar?idUsuario=${storageUsuarioId()}`, alerta, requestOptions)
      .then(() => {
        if (onSuccess)
          onSuccess();
      }).catch((error) => {
        if (onError)
          onError(error);
      });
  }
);

export const loadAlertasConfiguradas = createAsyncThunk(
  'alertas/loadAlertasConfiguradas',
  async (idUsuario) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}api/alerta?idUsuario=${idUsuario}`, requestOptions);
    return response && response.data;
  }
);

export const loadDetalleAlerta = createAsyncThunk(
  'alertas/loadDetalleAlerta',
  async (idAlerta) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}api/alerta/${idAlerta}/detalles?idUsuario=${storageUsuarioId()}`, requestOptions);
    return response && response.data;
  }
);

export const loadHistorialEjecucionAlerta = createAsyncThunk(
  'alertas/loadHistorialEjecucionAlerta',
  async (idAlerta) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}api/alerta/${idAlerta}/historialEjecucion?idUsuario=${storageUsuarioId()}`, requestOptions);
    return response && response.data;
  }
);

export const habilitarAlerta = createAsyncThunk(
  'alertas/habilitarAlerta',
  async (idAlerta, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/alerta/${idAlerta}/habilitar?habilitada=${true}&idUsuario=${storageUsuarioId()}`, {}, requestOptions)
      .then(() => dispatch(loadAlertasConfiguradas(storageUsuarioId())));
  }
);

export const deshabilitarAlerta = createAsyncThunk(
  'alertas/deshabilitarAlerta',
  async (idAlerta, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/alerta/${idAlerta}/habilitar?habilitada=${false}&idUsuario=${storageUsuarioId()}`, {}, requestOptions)
      .then(() => dispatch(loadAlertasConfiguradas(storageUsuarioId())));
  }
);

export const ejecutarAlerta = createAsyncThunk(
  'alertas/ejecutarAlerta',
  async (idAlerta, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/alerta/${idAlerta}/ejecutar?idUsuario=${storageUsuarioId()}`, {}, requestOptions)
      .then(() => dispatch(loadAlertasConfiguradas(storageUsuarioId())));
  }
);

export const eliminarAlerta = createAsyncThunk(
  'alertas/eliminarAlerta',
  async (idAlerta, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.delete(`${baseUrlAlertas()}api/alerta/${idAlerta}/eliminar?idUsuario=${storageUsuarioId()}`, requestOptions)
      .then(() => dispatch(loadAlertasConfiguradas(storageUsuarioId())))
      .then(() => dispatch(loadAlertasDisponibles()));
  }
);

export const enviarNotificacionAlerta = createAsyncThunk(
  'alertas/enviarNotificacion',
  async (idAlerta, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.post(`${baseUrlAlertas()}api/alerta/${idAlerta}/dummy?idUsuario=${storageUsuarioId()}`, {}, requestOptions)
      .then(() => dispatch(
        enqueueSnackbar({
          message: `Notificación enviada con éxito!`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      ))
      .catch((error) => {
        let errorMessage = `Error al enviar la notificación, intente nuevamente.`;
        console.log(error);
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      });
  }
);

export const loadConfiguracionesDefault = createAsyncThunk(
  'alertas/loadConfiguracionesDefault',
  async ({ idAlerta, onSuccess }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}obtenerConfiguracionesDefault?idAlerta=${idAlerta}`, requestOptions)
    
    if(onSuccess) {
      onSuccess(response.data);
    }

    return response && response.data;
  }
);

export const loadConfiguracionesDefaultPorTipo = createAsyncThunk(
  'alertas/loadConfiguracionesDefaultPorTipo',
  async ({ idTipoAlerta, idEmpresa, onSuccess }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}ObtenerConfiguraciones?idTipoAlerta=${idTipoAlerta}&idEmpresa=${idEmpresa}`, requestOptions);
    
    if(onSuccess) {
      onSuccess(response.data);
    }
    
    return response && response.data;
  }
);

export const alertasSlice = createSlice({
  name: 'alertas',
  initialState,
  reducers: {
    openCopiarAlertaModal: (state, action) => {
      state.alertaModal.alerta = action.payload;
      state.alertaModal.show = true;
      state.alertaModal.type = 'copy';
      state.alertaModal.title = 'Copiar alerta';
      state.alertaModal.maxWidth = 'md';
      state.configuraciones = {
        loading: false,
        estaCargado: false,
        items: [],
        error: null
      }
    },
    openActualizarAlertaModal: (state, action) => {
      state.alertaModal.alerta = action.payload;
      state.alertaModal.show = true;
      state.alertaModal.type = 'update';
      state.alertaModal.title = 'Editar alerta';
      state.alertaModal.maxWidth = 'md';
      state.configuraciones = {
        loading: false,
        estaCargado: false,
        items: [],
        error: null
      }
    },
    openEliminarAlertaModal: (state, action) => {
      state.alertaModal.alerta = action.payload;
      state.alertaModal.show = true;
      state.alertaModal.type = 'delete';
      state.alertaModal.title = 'Eliminar alerta';
      state.alertaModal.maxWidth = 'md';
    },
    openHistorialAlertaModal: (state, action) => {
      state.alertaModal.alerta = action.payload;
      state.alertaModal.show = true;
      state.alertaModal.type = 'history';
      state.alertaModal.title = 'Historial de ejecuciones';
      state.alertaModal.maxWidth = 'lg';
    },
    openEjecutarAlertaModal: (state, action) => {
      state.alertaModal.alerta = action.payload;
      state.alertaModal.show = true;
      state.alertaModal.type = 'execute';
      state.alertaModal.title = 'Ejecutar alerta';
      state.alertaModal.maxWidth = 'md';
    },
    closeAlertaModal: (state) => {
      state.alertaModal = {
        alerta: undefined,
        show: false,
        type: '',
        title: '',
        subTitle: '',
        maxWidth: 'md',
      };
      state.historialAlerta = {
        loading: false,
        estaCargado: false,
        registros: [],
        error: null,
      };
      state.configuraciones = {
        loading: false,
        estaCargado: false,
        items: [],
        error: null
      };
    },
    clearEjecutarAlerta: (state) => {
      state.alertaModal = {
        alerta: undefined,
        show: false,
        type: '',
        title: '',
        subTitle: '',
        maxWidth: 'md',
      };
      state.ejecutarAlerta = {
        ejecutando: false,
        ejecutada: false,
        error: null
      };
    },
    clearEliminarAlerta: (state) => {
      state.alertaModal = {
        alerta: undefined,
        show: false,
        type: '',
        title: '',
        subTitle: '',
        maxWidth: 'md',
      };
      state.eliminarAlerta = {
        ejecutando: false,
        ejecutada: false,
        error: null
      };
    },
    clearConfiguracionesAlerta: (state) => {      
      state.configuraciones = {
        loading: false,
        estaCargado: false,
        items: [],
        error: null
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAlertasConfiguradas.pending, (state) => {
        state.alertasConfiguradas = {
          loading: true,
          estaCargado: false,
          alertas: [],
          error: null,
        };
      })
      .addCase(loadAlertasConfiguradas.fulfilled, (state, action) => {
        state.alertasConfiguradas = {
          loading: false,
          estaCargado: true,
          alertas: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadAlertasConfiguradas.rejected, (state, error) => {
        state.alertasConfiguradas.loading = false;
        state.alertasConfiguradas.error = error;
        state.alertasConfiguradas.estaCargado = true;
      })
      .addCase(loadDetalleAlerta.pending, (state) => {
        state.detalleAlerta = {
          loading: true,
          estaCargado: false,
          alerta: undefined,
          error: null,
        };
      })
      .addCase(loadDetalleAlerta.fulfilled, (state, action) => {
        state.detalleAlerta = {
          loading: false,
          estaCargado: true,
          alerta: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadDetalleAlerta.rejected, (state, error) => {
        state.detalleAlerta = {
          loading: false,
          estaCargado: true,
          alerta: undefined,
          error: error
        };
      })
      .addCase(loadHistorialEjecucionAlerta.pending, (state) => {
        state.historialAlerta = {
          loading: true,
          estaCargado: false,
          registros: [],
          error: null,
        };
      })
      .addCase(loadHistorialEjecucionAlerta.fulfilled, (state, action) => {
        state.historialAlerta = {
          loading: false,
          estaCargado: true,
          registros: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadHistorialEjecucionAlerta.rejected, (state, error) => {
        state.historialAlerta = {
          loading: false,
          estaCargado: true,
          registros: [],
          error: error
        };
      })
      .addCase(ejecutarAlerta.pending, (state) => {
        state.ejecutarAlerta = {
          ejecutando: true,
          ejecutada: false,
          error: null,
        };
      })
      .addCase(ejecutarAlerta.fulfilled, (state) => {
        state.ejecutarAlerta = {
          ejecutando: false,
          ejecutada: true,
          error: null,
        };
      })
      .addCase(ejecutarAlerta.rejected, (state, error) => {
        state.ejecutarAlerta = {
          ejecutando: false,
          ejecutada: true,
          error: error
        };
      })
      .addCase(eliminarAlerta.pending, (state) => {
        state.eliminarAlerta = {
          ejecutando: true,
          ejecutada: false,
          error: null,
        };
      })
      .addCase(eliminarAlerta.fulfilled, (state) => {
        state.eliminarAlerta = {
          ejecutando: false,
          ejecutada: true,
          error: null,
        };
      })
      .addCase(eliminarAlerta.rejected, (state, error) => {
        state.eliminarAlerta = {
          ejecutando: false,
          ejecutada: true,
          error: error
        };
      })
      .addCase(loadConfiguracionesDefault.pending, (state) => {
        state.configuraciones = {
          loading: true,
          estaCargado: false,
          items: [],
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefault.fulfilled, (state, action) => {
        state.configuraciones = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefault.rejected, (state, error) => {
        state.configuraciones = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
      .addCase(loadConfiguracionesDefaultPorTipo.pending, (state) => {
        state.configuraciones = {
          loading: true,
          estaCargado: false,
          items: [],
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefaultPorTipo.fulfilled, (state, action) => {
        state.configuraciones = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefaultPorTipo.rejected, (state, error) => {
        state.configuraciones = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
  }
});

export const { openCopiarAlertaModal, openActualizarAlertaModal, openEliminarAlertaModal,
  openHistorialAlertaModal, openEjecutarAlertaModal, closeAlertaModal, clearEjecutarAlerta,
  clearEliminarAlerta, clearConfiguracionesAlerta } = alertasSlice.actions;
export default alertasSlice.reducer;