import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CategoriasConfigBody from './CategoriasConfigBody';
import { loadCategoriasDeProveedor } from '../../../store/slices/configuracionesSlice';

const CategoriasDeCliente = (props) => {
  const dispatch = useDispatch();
  const categorias = useSelector(state => state.configuraciones.categoriasDeProveedor.items);
  const loading = useSelector(state => state.configuraciones.categoriasDeProveedor.loading);
  const onLoad = (empresaId) => dispatch(loadCategoriasDeProveedor(empresaId));

  const {
    empresaId,
    onChangeValue,
    configInicial,
    seleccionarTodos,
    deseleccionarTodos
  } = props;

  return (
    <CategoriasConfigBody
      categorias={categorias}
      empresaId={empresaId}
      loading={loading}
      onChangeValue={onChangeValue}
      onLoad={onLoad}
      configInicial={configInicial}
      seleccionarTodos={seleccionarTodos}
      deseleccionarTodos={deseleccionarTodos}
    />
  );
};

export default CategoriasDeCliente;
