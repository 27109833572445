import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import classes from './Login.module.css';
import { checkValidity } from '../../../assets/shared/utility';
import LogoGauss from '../../../assets/images/LogoGauss.png';
import { urlPortal } from '../../../assets/shared/urlApps';
import { login } from '../../../store/slices/authSlice';

const Login = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => state.auth);

  useEffect(() => {
    urlPortal();
  }, []);

  const [controls, setControls] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'Email Address',
        placeholder: 'Email'
      },
      value: '',
      validation: {
        required: true,
        isEmail: true
      },
      valid: false,
      touched: false,
      errorMessage: '',
      autoComplete: 'email'
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Password'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false,
      errorMessage: '',
      autoComplete: 'new-password'
    }
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    updatedControl.value = event.target.value;
    const [isValid, errorMessage] = checkValidity(event.target.value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid && (!updatedControls[formElementKey].validation || updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(login(controls.email.value, controls.password.value))
  };

  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({ id: key, ...controls[key] });
  }

  let form = formElementsArray.map((elem, key) => (
    <Input
      key={elem.id}
      id={elem.id}
      elementType={elem.elementType}
      elementConfig={elem.elementConfig}
      value={elem.value}
      valueChanged={(event) => inputValueChangedHandler(event, elem.id)}
      invalid={!elem.valid}
      shouldValidate={elem.validation}
      touched={elem.touched}
      errorMessage={elem.errorMessage}
    />
  ));

  const errorHttp = error !== null && <p style={{ color: 'red', display: 'inline' }}>{error}</p>;

  return (
    <div className={classes.Login}>
      {errorHttp}
      <form onSubmit={submitHandler}>
        <img src={LogoGauss} alt="Logo Gauss" />
        {form}
        <Button
          style={{ margin: '5px auto 5px auto', width: '90%' }}
          submit
          disabled={!formIsValid}
          loading={loading}
        >
          Iniciar sesión
        </Button>
      </form>
    </div>
  );
};

export default Login;
