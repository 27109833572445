import React, { useEffect, useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch } from 'react-redux';
import { enviarNotificacionInforme } from "../../../../store/slices/informesSlice";
import { loadInformesDisponibles } from "../../../../store/slices/tipoInformeSlice";
import { useSelector } from "react-redux";

const TestInformes = () => {
  const dispatch = useDispatch();
  const { informes } = useSelector(state => state.tipoInforme.informesDisponibles);
  const [ informe, setInforme ] = useState('');

  useEffect(() => {
    dispatch(loadInformesDisponibles())
  }, [dispatch]);

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={3} lg={2}>
        <FormControl variant="standard" style={{ width: '100%' }}>
          <InputLabel id="informes">Informe</InputLabel>
          <Select
            labelId="informes"
            id="informes"
            value={informe}
            onChange={(event) => setInforme(event.target.value)}
            style={{ fontSize: 'small' }}
          >
            {informes && informes.map((item, index) => (
              <MenuItem
                key={index}
                value={item.id}
                style={{ fontSize: 'small' }}>
                {item.tituloGauss}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionInforme(informe))}
          color='info'
          variant='contained'
          disabled={!informe}
          fullWidth={true}
          title="INFORME SALDO ACREEDORES"
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Enviar Notificación
        </Button>
      </Grid>
    </Grid>
  )
}

export default TestInformes;