import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.module.css";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';

import { ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import LightTooltip from "../../UI/LightTooltip/LightTooltip";
import { cambioPaginaRender } from "../../../store/slices/ejecucionPaginasSlice";

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    color: 'white !important',
    "&:focus": {
      background: "linear-gradient(90deg, #fbb040, #f15a29)",
    },
  },
  listItemSelected: {
    color: 'white !important',
    background: "linear-gradient(90deg, #fbb040, #f15a29)",
  },
}));

const NavigationItem = (props) => {
  const classes2 = useStyles();
  const dispatch = useDispatch();
  const {
    roles,
    currentUserRoles,
    title,
    style,
    text,
    link,
    exact,
    children,
  } = props;
  
  const pageRender = useSelector((state) => state.ejecucionPaginas.paginaRender);
  const checkRoles = roles && roles.indexOf(currentUserRoles) === -1;

  const onItemClick = () => {
    sessionStorage.setItem('seccionNombre', props.title);
    props.onClickItem();
    dispatch(cambioPaginaRender(link));
  }

  return !checkRoles ? (
    <Fragment>
      <LightTooltip arrow title={title} placement="right">
        <ListItemButton
          classes={{
            root: classes2.listItemRoot,
            selected: classes2.listItemSelected
          }}
          style={style}
          button="true"
          key={text}
          component={NavLink}
          to={link}
          exact={exact}
          onClick={() => onItemClick()}
          selected={pageRender === link}
        >
          <ListItemIcon className={classes.NavigationItemIcon}>
            {children}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </LightTooltip>
    </Fragment>
  ) : null;
};

export default NavigationItem;