import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Spinner from '../Spinner/Spinner';
import { TextField } from '@mui/material';

const EstadosChequesSelector = (props) => {
  const estados = useSelector(state => state.configuraciones.loadEstadosCheques.items);
  const loading = useSelector(state => state.configuraciones.loadEstadosCheques.loading);
  const { hiddenEstados, flexChangeEstados, onChangeEstado } = props;

  const [localValue, setLocalValue] = useState('0');
  const [estadosLocal, setEstadosLocal] = useState([]);

  useEffect(() => {
    if (hiddenEstados) {
      setEstadosLocal(estados.filter((ar) => !hiddenEstados.find((rm) => rm.id_estado === ar.id_estado)));
      setLocalValue('0');
    } else {
      setEstadosLocal(estados);
    }
  }, [hiddenEstados, flexChangeEstados]);

  return loading ? (
    <Spinner size="small" />
  ) : (
    <FormControl style={{ margin: '0px 10px 0px 10px', width: '140px' }}>
      <TextField
        id="estadoSelect"
        labelId="estadoLabel"
        select
        label="Estado"
        value={localValue}
        variant="standard"
        size="small"
        onChange={(event) => setLocalValue(event.target.value)}
      >
        <MenuItem key={0} value={'0'} onClick={() => onChangeEstado(null)}>
          Estado de valor
        </MenuItem>
        {estadosLocal &&
          estadosLocal.map((estado, index) => (
            <MenuItem key={index} value={estado.id_estado} onClick={() => onChangeEstado(estado)}>
              {estado.nombre}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
};

export default EstadosChequesSelector;