import React from "react";
import classes from './InformeDisponible.module.css';
import {  Grid, Typography } from "@mui/material";

const InformeDisponibleAyuda = (props) => {
  const { informe } = props;

  return (
    <Grid container spacing={1} sx={{ minWidth: '550px', minHeight: '150px' }}>
      <Grid item xs={12}>
        <Typography>
          <strong>Informe: </strong> <span>{informe && informe.tituloGauss}</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <strong>Descripción</strong>
        </Typography>
        <Typography className={classes.TextCard}>
          {informe && informe.descripcionGauss}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default InformeDisponibleAyuda;