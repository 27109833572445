import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken, storageUsuarioId } from "../../assets/shared/sessionData";
import axios from '../../axios-alertas';
import { enqueueSnackbar } from "./snackbarSlice";
import { baseUrlAlertas } from "../../assets/shared/urlApps";
import { loadInformesDisponibles } from "./tipoInformeSlice";

const initialState = {
  informesConfigurados: {
    loading: false,
    estaCargado: false,
    informes: [],
    error: null
  },
  informeModal: {
    informe: undefined,
    show: false,
    type: '',
    title: '',
    subTitle: '',
    maxWidth: 'md',
  },
  detalleInforme: {
    informe: undefined,
    loading: false,
    estaCargado: false,
    error: null
  },
  historialInforme: {
    loading: false,
    estaCargado: false,
    registros: [],
    error: null
  },
  ejecutarInforme: {
    ejecutando: false,
    ejecutada: false,
    error: null
  },
  eliminarInforme: {
    ejecutando: false,
    ejecutada: false,
    error: null
  },
  configuraciones: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
  frecuencias: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
};

export const crearInforme = createAsyncThunk(
  'informes/crearInforme',
  async ({ informe, onSuccess, onError }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const idUsuario = storageUsuarioId();
    await axios.post(`${baseUrlAlertas()}informes/crearInforme?idUsuario=${idUsuario}`, informe, requestOptions)
      .then(() => {
        if (onSuccess)
          onSuccess();
      }).catch((error) => {
        if (onError)
          onError(error);
      });
  }
);

export const actualizarInforme = createAsyncThunk(
  'informes/actualizarInforme',
  async ({ informe, onSuccess, onError }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const idUsuario = storageUsuarioId();
    await axios.put(`${baseUrlAlertas()}informes/${informe.id}/editar?idUsuario=${idUsuario}`, informe, requestOptions)
      .then(() => {
        if (onSuccess)
          onSuccess();
      }).catch((error) => {
        if (onError)
          onError(error);
      });
  }
);

export const loadInformesConfigurados = createAsyncThunk(
  'informes/loadInformesConfigurados',
  async (idUsuario) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}informes/ObtenerInformesConfigurados?idUsuario=${idUsuario}`, requestOptions);
    return response && response.data;
  }
);

export const loadDetalleInforme = createAsyncThunk(
  'informes/loadDetalleInforme',
  async (idInforme) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}informes/${idInforme}?idUsuario=${idUsuario}`, requestOptions);
    return response && response.data;
  }
);

export const loadHistorialEjecucionInforme = createAsyncThunk(
  'informes/loadHistorialEjecucionInforme',
  async (idInforme) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}informes/${idInforme}/historialEjecucion`, requestOptions);
    return response && response.data;
  }
);

export const habilitarInforme = createAsyncThunk(
  'informes/habilitarInforme',
  async (idInforme, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}informes/${idInforme}/habilitar?idUsuario=${idUsuario}&habilitar=${true}`, {}, requestOptions)
      .then(() => dispatch(loadInformesConfigurados(storageUsuarioId())));
  }
);

export const deshabilitarInforme = createAsyncThunk(
  'informes/deshabilitarInforme',
  async (idInforme, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}informes/${idInforme}/habilitar?idUsuario=${idUsuario}&habilitar=${false}`, {}, requestOptions)
      .then(() => dispatch(loadInformesConfigurados(storageUsuarioId())));
  }
);

export const ejecutarInforme = createAsyncThunk(
  'informes/ejecutarInforme',
  async (idInforme, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const ids = [];
    ids.push(idInforme);
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}informes/ejecutar?idUsuario=${idUsuario}`, ids, requestOptions)
      .then(() => dispatch(loadInformesConfigurados(storageUsuarioId())));
  }
);

export const eliminarInforme = createAsyncThunk(
  'informes/eliminarInforme',
  async (idInforme, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.delete(`${baseUrlAlertas()}informes/borrar?id=${idInforme}&idUsuario=${idUsuario}`, requestOptions)
      .then(() => dispatch(loadInformesConfigurados(storageUsuarioId())))
      .then(() => dispatch(loadInformesDisponibles()));
  }
);

export const enviarNotificacionInforme = createAsyncThunk(
  'informes/enviarNotificacion',
  async (idInforme, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.post(`${baseUrlAlertas()}informes/${idInforme}/dummy?idUsuario=${idUsuario}`, {}, requestOptions)
      .then(() => dispatch(        
        enqueueSnackbar({
          message: `Notificación enviada con éxito!`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success'
          }
        })
      ))
      .catch((error) => {
        let errorMessage = `Error al enviar la notificación, intente nuevamente.`;
        console.log(error);
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "error",
            },
          })
        );
      });
  }
);

export const loadConfiguracionesDefault = createAsyncThunk(
  'informes/loadConfiguracionesDefault',
  async ({ idInforme, onSuccess }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}obtenerConfiguracionesDefault?idInforme=${idInforme}`, requestOptions);
    
    if(onSuccess) {
      onSuccess(response.data);
    }
    
    return response && response.data;
  }
);

export const loadConfiguracionesDefaultPorTipo = createAsyncThunk(
  'informes/loadConfiguracionesDefaultPorTipo',
  async ({ idTipoInforme, idEmpresa, onSuccess }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}ObtenerConfiguraciones?idTipoInforme=${idTipoInforme}&idEmpresa=${idEmpresa}`, requestOptions);
    
    if(onSuccess) {
      onSuccess(response.data);
    }
    
    return response && response.data;
  }
);

export const loadFrecuencias = createAsyncThunk(
  'informes/loadFrecuencias',
  async () => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}ObtenerFrecuencias`, requestOptions);
    return response && response.data;
  });

export const informesSlice = createSlice({
  name: 'informes',
  initialState,
  reducers: {
    openCopiarInformeModal: (state, action) => {
      state.informeModal.informe = action.payload;
      state.informeModal.show = true;
      state.informeModal.type = 'copy';
      state.informeModal.title = 'Copiar informe';
      state.informeModal.maxWidth = 'md';
    },
    openActualizarInformeModal: (state, action) => {
      state.informeModal.informe = action.payload;
      state.informeModal.show = true;
      state.informeModal.type = 'update';
      state.informeModal.title = 'Editar informe';
      state.informeModal.maxWidth = 'md';
    },
    openEliminarInformeModal: (state, action) => {
      state.informeModal.informe = action.payload;
      state.informeModal.show = true;
      state.informeModal.type = 'delete';
      state.informeModal.title = 'Eliminar informe';
      state.informeModal.maxWidth = 'md';
    },
    openHistorialInformeModal: (state, action) => {
      state.informeModal.informe = action.payload;
      state.informeModal.show = true;
      state.informeModal.type = 'history';
      state.informeModal.title = 'Historial de ejecuciones';
      state.informeModal.maxWidth = 'lg';
    },
    openEjecutarInformeModal: (state, action) => {
      state.informeModal.informe = action.payload;
      state.informeModal.show = true;
      state.informeModal.type = 'execute';
      state.informeModal.title = 'Ejecutar informe';
      state.informeModal.maxWidth = 'md';
    },
    closeInformeModal: (state) => {
      state.informeModal = {
        informe: undefined,
        show: false,
        type: '',
        title: '',
        subTitle: '',
        maxWidth: 'md',
      };
      state.historialInforme = {
        loading: false,
        estaCargado: false,
        registros: [],
        error: null,
      };
      state.configuraciones = {
        loading: false,
        estaCargado: false,
        items: [],
        error: null
      };
    },
    clearEjecutarInforme: (state) => {
      state.informeModal.informe = undefined;
      state.informeModal.show = false;
      state.informeModal.type = '';
      state.informeModal.title = '';
      state.informeModal.maxWidth = 'md';
      state.ejecutarInforme = {
        ejecutando: false,
        ejecutada: false,
        error: null
      };
    },
    clearEliminarInforme: (state) => {
      state.informeModal = {
        informe: undefined,
        show: false,
        type: '',
        title: '',
        subTitle: '',
        maxWidth: 'md',
      };
      state.eliminarInforme = {
        ejecutando: false,
        ejecutada: false,
        error: null
      };
    },
    clearConfiguracionesInforme: (state) => {      
      state.configuraciones = {
        loading: false,
        estaCargado: false,
        items: [],
        error: null
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadInformesConfigurados.pending, (state) => {
        state.informesConfigurados = {
          loading: true,
          estaCargado: false,
          informes: [],
          error: null,
        };
      })
      .addCase(loadInformesConfigurados.fulfilled, (state, action) => {
        state.informesConfigurados = {
          loading: false,
          estaCargado: true,
          informes: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadInformesConfigurados.rejected, (state, error) => {
        state.informesConfigurados.loading = false;
        state.informesConfigurados.error = error;
        state.informesConfigurados.estaCargado = true;
      })
      .addCase(loadHistorialEjecucionInforme.pending, (state) => {
        state.historialInforme = {
          loading: true,
          estaCargado: false,
          registros: [],
          error: null,
        };
      })
      .addCase(loadHistorialEjecucionInforme.fulfilled, (state, action) => {
        state.historialInforme = {
          loading: false,
          estaCargado: true,
          registros: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadHistorialEjecucionInforme.rejected, (state, error) => {
        state.historialInforme = {
          loading: false,
          estaCargado: true,
          registros: [],
          error: error
        };
      })
      .addCase(ejecutarInforme.pending, (state) => {
        state.ejecutarInforme = {
          ejecutando: true,
          ejecutada: false,
          error: null,
        };
      })
      .addCase(ejecutarInforme.fulfilled, (state) => {
        state.ejecutarInforme = {
          ejecutando: false,
          ejecutada: true,
          error: null,
        };
      })
      .addCase(ejecutarInforme.rejected, (state, error) => {
        state.ejecutarInforme = {
          ejecutando: false,
          ejecutada: true,
          error: error
        };
      })
      .addCase(eliminarInforme.pending, (state) => {
        state.eliminarInforme = {
          ejecutando: true,
          ejecutada: false,
          error: null,
        };
      })
      .addCase(eliminarInforme.fulfilled, (state) => {
        state.eliminarInforme = {
          ejecutando: false,
          ejecutada: true,
          error: null,
        };
      })
      .addCase(eliminarInforme.rejected, (state, error) => {
        state.eliminarInforme = {
          ejecutando: false,
          ejecutada: true,
          error: error
        };
      })
      .addCase(loadConfiguracionesDefault.pending, (state) => {
        state.configuraciones = {
          loading: true,
          estaCargado: false,
          items: [],
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefault.fulfilled, (state, action) => {
        state.configuraciones = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefault.rejected, (state, error) => {
        state.configuraciones = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
      .addCase(loadConfiguracionesDefaultPorTipo.pending, (state) => {
        state.configuraciones = {
          loading: true,
          estaCargado: false,
          items: [],
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefaultPorTipo.fulfilled, (state, action) => {
        state.configuraciones = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadConfiguracionesDefaultPorTipo.rejected, (state, error) => {
        state.configuraciones = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
      .addCase(loadDetalleInforme.pending, (state) => {
        state.detalleInforme = {
          loading: true,
          estaCargado: false,
          informe: undefined,
          error: null,
        };
      })
      .addCase(loadDetalleInforme.fulfilled, (state, action) => {
        state.detalleInforme = {
          loading: false,
          estaCargado: true,
          informe: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadDetalleInforme.rejected, (state, error) => {
        state.detalleInforme = {
          loading: false,
          estaCargado: true,
          informe: undefined,
          error: error
        };
      })
      .addCase(loadFrecuencias.pending, (state) => {
        state.frecuencias = {
          loading: true,
          estaCargado: false,
          items: [],
          error: null,
        };
      })
      .addCase(loadFrecuencias.fulfilled, (state, action) => {
        let items = action && action.payload && action.payload;

        if(items) {
          items.forEach((item) => {
            item['nombre'] = item.descripcion;
          })
        }

        state.frecuencias = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadFrecuencias.rejected, (state, error) => {
        state.frecuencias = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
  }
});

export const { openCopiarInformeModal, openActualizarInformeModal, openEliminarInformeModal,
  openHistorialInformeModal, openEjecutarInformeModal, closeInformeModal, clearEjecutarInforme,
  clearEliminarInforme, clearConfiguracionesInforme } = informesSlice.actions;
export default informesSlice.reducer;