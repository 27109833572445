import React from 'react';
import Avatar from '@mui/material/Avatar';

import './AvatarUsuarioLog.module.css';

const AvatarUsuarioLog = () => {
  const email = sessionStorage.getItem('userEmail');
  var email_analizado = email.split('@');

  return (
    <Avatar style={{ backgroundColor: '#f15a29' }}>
      {email_analizado
        .map((item) => item.charAt(0).toUpperCase())
        .slice(0, 2)
        .join('')}
    </Avatar>
  );
};

export default AvatarUsuarioLog;
