import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import Dialog from "../Dialog/Dialog";
import { Typography, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EnviarChartEmailModal from "./EnviarChartEmailModal";
import { showSendChartModal } from "../../../store/slices/modalsSlice";

const useStyles = makeStyles({ 
  titleTexto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
});

const EnviarChart = () => {
  const dispatch = useDispatch();
  const sendChartButton = useSelector(state => state.modals.sendChartModal);
  const classes = useStyles();

  const onClose = () => {
    dispatch(showSendChartModal(false, null));
  }

  const sendChartDialog = () => {
    return (
      <EnviarChartEmailModal />
    )
  }

  return (
    <Fragment>
      <div id="enviarChart"></div>
      {sendChartButton.show && (
        <Dialog
          fullWidth
          maxWidth={"sm"}
          title={
            <div style={{ width: "100%" }}>
              <div className={classes.titleTexto}>
                <Typography style={{ fontSize: "large" }}>Enviar gráfico</Typography>
                <IconButton aria-label="close" onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider />
            </div>
          }
          show={sendChartButton.show}
          body={sendChartDialog()}
          onClose={() => onClose()}
        />
      )}
    </Fragment>
  );
}

export default EnviarChart;