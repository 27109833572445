import React, { useState } from "react";
import classes from './InformeDisponible.module.css';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import InformeDisponibleCard from "./InformeDisponibleCard";

const InformeDisponibleCategoriaCard = (props) => {
  const { categoria, informes, index } = props;
  const [openInformes, setOpenInformes] = useState(false);

  return (
    <Grid item xs={12} container spacing={1} 
      className={classes.ItemCard} sx={{ marginInline: '8px' }}
      direction='row' justifyContent='space-between' alignContent='center'>
      <Grid item xs={11}>
        <Typography variant="h6">
          <strong>{categoria && categoria !== '' ? categoria : `Categoría ${index + 1}`}</strong>
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{ textAlign: 'end' }}>
        <Tooltip title={openInformes ? 'Colapsar informes' : 'Expandir informes'}>
          <IconButton size='large' onClick={() => setOpenInformes(!openInformes)}>
            {openInformes ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Tooltip>
      </Grid>
      {openInformes && informes && informes.map((informe, index) => {
        return (
          <InformeDisponibleCard
            key={index}
            index={index}
            informe={informe}
          />
        )
      })}
    </Grid>
  )
}

export default InformeDisponibleCategoriaCard;