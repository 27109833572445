import React, { useState } from "react";
import { enviarNotificacionInterna } from "../../../store/slices/notificacionesSlice";
import { useDispatch } from 'react-redux';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";

const NotificacionInterna = () => {
  const dispatch = useDispatch();
  const [titulo, setTitulo] = useState('');
  const [detalle, setDetalle] = useState('');
  const [enviarMail, setEnviarMail] = useState(true);
  const [enviarApp, setEnviarApp] = useState(true);
  const [enviando, setEnviando] = useState(false);

  const isValid = () => {
    return titulo && titulo !== '' && detalle && detalle !== '' && (enviarMail || enviarApp);
  }

  const onEnviarNotificacionClick = () => {
    setEnviando(true);

    const onSuccess = () => {
      setTimeout(() => {
        setEnviando(false);
      }, 500);
    }

    const notificacion = {
      titulo,
      detalle,
      enviarNotificacionMail: enviarMail,
      enviarNotificacionApp: enviarApp
    }
    dispatch(enviarNotificacionInterna({ notificacion, onSuccess }));
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          id="titulo"
          name="titulo"
          label="Titulo"
          value={titulo}
          onChange={(event) => setTitulo(event.target.value)}
          required={true}
          disabled={enviando}
          fullWidth={true}
          size="small"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="detalle"
          name="detalle"
          label="Detalle"
          value={detalle}
          onChange={(event) => setDetalle(event.target.value)}
          required={true}
          disabled={enviando}
          fullWidth={true}
          size="small"
        />
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6}>
          <FormControlLabel label="Enviar Notificación por Mail" control={
            <Checkbox
              id="enviarMail"
              name="enviarMaill"
              checked={enviarMail}
              onChange={(event) => setEnviarMail(event.target.checked)}
              disabled={false}
              value={enviarMail}
            />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel label="Enviar Notificación por App" control={
            <Checkbox
              id="enviarApp"
              name="enviarApp"
              checked={enviarApp}
              onChange={(event) => setEnviarApp(event.target.checked)}
              disabled={enviando}
            />}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onEnviarNotificacionClick}
          color='info'
          variant='contained'
          fullWidth={true}
          disabled={!isValid() || enviando}
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Enviar Notificación
        </Button>
      </Grid>
    </Grid>
  )
}

export default NotificacionInterna;