import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './Informes.module.css';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  NotificationsActiveOutlined, NotificationsOffOutlined, EditOutlined, HistoryEduOutlined,
  ContentCopyOutlined, PlayCircleOutlined, DeleteOutlineOutlined
} from '@mui/icons-material';
import {
  habilitarInforme, deshabilitarInforme, loadInformesConfigurados,
  openCopiarInformeModal, openActualizarInformeModal, openHistorialInformeModal,
  openEjecutarInformeModal, clearEjecutarInforme, openEliminarInformeModal,
  clearEliminarInforme
} from "../../../../store/slices/informesSlice";
import { storageUsuarioId } from '../../../../assets/shared/sessionData';
import moment from 'moment';
import { enqueueSnackbar } from '../../../../store/slices/snackbarSlice';

const InformeConfiguradoCard = (props) => {
  const dispatch = useDispatch();
  const { informe } = props;

  const onHabilitarInforme = (informe) => {
    dispatch(habilitarInforme(informe.id));
    dispatch(loadInformesConfigurados(storageUsuarioId()));
  }

  const onDeshabilitarInforme = (informe) => {
    dispatch(deshabilitarInforme(informe.id));
    dispatch(loadInformesConfigurados(storageUsuarioId()));
  }

  const onEjecutarInforme = (informe) => {
    if (informe.habilitar) {
      dispatch(clearEjecutarInforme());
      dispatch(openEjecutarInformeModal(informe));
    } else {
      dispatch(enqueueSnackbar({
        message: "El informe se encuentra deshabilitado, no se puede ejecutar manualmente.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
        },
      }));
    }
  }

  const onEliminarInforme = (informe) => {
    dispatch(clearEliminarInforme());
    dispatch(openEliminarInformeModal(informe));
  }

  const onCopiarInforme = (informe) => {
    const puedeConfigurar = informe && informe.tipoInforme
      && informe.tipoInforme.cantidadCreacionesInforme < informe.tipoInforme.cantidadConfiguracionesInforme;

    if (puedeConfigurar) {
      dispatch(openCopiarInformeModal(informe));
    } else {
      dispatch(enqueueSnackbar({
        message: "Se ha alcanzado el número máximo de configuraciones permitidas.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
        },
      }));
    }
  }

  return (
    <Grid item xs={12} lg={6} container style={{ maxWidth: '95%' }}>
      <Grid item xs={12} container className={classes.ItemCard}>
        <Grid item xs={8} container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              <strong style={{ fontSize: 'small' }}>Informe: </strong> <span>{informe && informe.tituloInforme}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Empresa: </strong><br />
                <span>{informe && informe.empresa && informe.empresa.nombre}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Propietario: </strong><br />
                <span>{informe && informe.usuario && informe.usuario.nombreApellido}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Última ejecución: </strong><br />
                <span>{informe && informe.ultimaEjecucion ? moment(informe.ultimaEjecucion).format('HH:mm DD/MM/yyyy') : ''}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={2}>
            {informe.habilitar ? (
              <Tooltip title="Deshabilitar informe">
                <IconButton onClick={() => onDeshabilitarInforme(informe)}>
                  <NotificationsActiveOutlined htmlColor='#F46B2C' />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Para habilitar informe debe ejecutar una prueba exitosa del mismo">
                <IconButton onClick={() => { }}>
                  <NotificationsOffOutlined htmlColor='#515151' />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Editar informe">
              <IconButton onClick={() => dispatch(openActualizarInformeModal(informe))}>
                <EditOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Eliminar informe">
              <IconButton onClick={() => onEliminarInforme(informe)}>
                <DeleteOutlineOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Historial de ejecuciones">
              <IconButton onClick={() => dispatch(openHistorialInformeModal(informe))}>
                <HistoryEduOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Copiar informe">
              <IconButton onClick={() => onCopiarInforme(informe)}>
                <ContentCopyOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Ejecutar informe manualmente">
              <IconButton onClick={() => onEjecutarInforme(informe)}>
                <PlayCircleOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default InformeConfiguradoCard;