import React, { Fragment } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import classes from './Informes.module.css';
import { ejecutarInforme } from "../../../../store/slices/informesSlice";
import { closeInformeModal } from "../../../../store/slices/informesSlice";
import { useSelector } from "react-redux";
import Spinner from "../../Spinner/Spinner";

const InformeConfiguradoEjecutar = (props) => {
  const dispatch = useDispatch();
  const { informe } = props;
  const ejecutando = useSelector(state => state.informes.ejecutarInforme.ejecutando);
  const ejecutada = useSelector(state => state.informes.ejecutarInforme.ejecutada);
  const error = useSelector(state => state.informes.ejecutarInforme.error);

  const renderEjecutada = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Informe ejecutado correctamente.
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeInformeModal())}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const renderError = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {'Ha ocurrido un error al ejecutar el informe' + (informe ? ` ${informe.tituloInforme}` : '') + '. Desea reintentar nuevamente?'}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(ejecutarInforme(informe.id))}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Ejecutar
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeInformeModal())}
              className={classes.ModalButton}
              variant="contained"
              style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const renderEjecutar = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {'¿Confirma la ejecución de la informe' + (informe ? ` ${informe.tituloInforme}` : '') + '?'}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(ejecutarInforme(informe.id))}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Ejecutar
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeInformeModal())}
              className={classes.ModalButton}
              variant="contained"
              style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container spacing={1} direction='column' justifyContent='center' alignContent='center' style={{ minWidth: '550px', minHeight: '150px' }}>
      {ejecutando ? (
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Spinner />
        </Grid>
      ) : ejecutada
        ? error ? renderError() : renderEjecutada()
        : renderEjecutar()
      }
    </Grid>
  )
}

export default InformeConfiguradoEjecutar;