import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '3px',
    zIndex: 2300
  },
  popper: {
    zIndex: 1500
  }
}))(Tooltip);

export default LightTooltip;
