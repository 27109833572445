import React, { useState, Fragment, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Chip, Grid, List, IconButton, Typography, Tooltip, FormControl, InputLabel, Select, MenuItem, Button, Divider, FormHelperText } from "@mui/material";
import { customCheckbox, customControls, customInput, customSelect } from '../../../../assets/shared/controls';
import { checkValidity, isJSON } from '../../../../assets/shared/utility';
import { AddOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import classes from './Informes.module.css';
import Dialog from "../../Dialog/Dialog";
import { Close } from "@mui/icons-material";
import Spinner from '../../Spinner/Spinner';
import {
  actualizarInforme, closeInformeModal, crearInforme, loadInformesConfigurados, loadConfiguracionesDefault,
  loadConfiguracionesDefaultPorTipo, loadDetalleInforme,
  ejecutarInforme
} from '../../../../store/slices/informesSlice';
import { enqueueSnackbar } from '../../../../store/slices/snackbarSlice';
import { storageMonedaId, storageUsuarioId } from '../../../../assets/shared/sessionData';
import { closeInformeDisponibleModal, loadInformesDisponibles } from "../../../../store/slices/tipoInformeSlice";
import InformeFrecuencia from './InformeFrecuencia';

const EmailContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const InformeConfiguradoDetalle = (props) => {
  const dispatch = useDispatch();
  const { informe, informeId, canEdit, empresas, usuarios } = props;
  const detalleInforme = useSelector((state) => state.informes.detalleInforme.informe);
  const [cronExpression, setCronExpression] = useState();
  const [formIsValid, setFormIsValid] = useState(false);
  const [show, setShow] = useState(false);
  const [showProbar, setShowProbar] = useState(false);
  const [showFrecuencia, setShowFrecuencia] = useState(false);
  const [configuraciones, setConfiguraciones] = useState();
  const [pruebaSuccess, setPruebaSuccess] = useState(informe && informe.id !== 0);
  const usuariosWeb = usuarios && usuarios.filter((u) => u.accesoWeb);
  const usuariosApp = usuarios && usuarios.filter((u) => u.accesoApp);
  const usuariosSelect = useMemo(() => {
    return usuarios && usuarios.map((user) => {
      return {
        id: user.id,
        nombre: `${user.nombre} ${user.apellido}`
      }
    })
  }, [usuarios]);

  const loadingConfiguraciones = useSelector((state) => state.informes.configuraciones.loading);

  const styleSwitch = {
    width: '100%',
    marginLeft: '0px',
    marginRight: '20px',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }

  const inputLabelProps = {
    style: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      width: '100%',
    }
  }

  const getResponsable = (informe) => {
    if (informe) {
      const item = usuariosSelect.find((x) => x.id === informe.usuarioId);
      return item;
    }
  }

  const [destinatariosEmail, setDestinatariosEmail] = useState(informe.destinatariosEmail);
  const [destinatariosApp, setDestinatariosApp] = useState(informe.destinatariosApp);
  const [controls, setControls] = useState({
    tituloInforme: {
      name: "tituloInforme",
      label: "Título Informe *",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: informe ? informe.tituloInforme : '',
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "Campo requerido",
    },
    descripcionInforme: {
      name: "descripcionInforme",
      label: "Descripción Informe *",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: !canEdit,
      value: informe ? informe.descripcionInforme : '',
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    responsable: {
      name: "usuario",
      label: "Responsable de contacto",
      placeholder: "",
      elementType: "select",
      elementConfig: {
        type: "select",
        inputLabelProps
      },
      disabled: !canEdit,
      value: getResponsable(informe),
      validation: {
        required: true,
      },
      valid: true,
      required: true,
      touched: false,
      errorMessage: "Campo requerido",
      items: usuariosSelect
    },
    empresa: {
      name: "empresa",
      label: "Empresa *",
      placeholder: "",
      elementType: "select",
      elementConfig: {
        type: "select",
        inputLabelProps
      },
      disabled: !canEdit,
      value: informe ? informe.empresa : '',
      validation: {
        required: true,
      },
      valid: true,
      required: true,
      touched: false,
      errorMessage: "Campo requerido",
      items: empresas
    },
    notificarPorMail: {
      elementType: "switch",
      name: "notificarEmail",
      label: "Notificarme por mail",
      elementConfig: {
        type: "switch",
      },
      validation: {},
      disabled: !canEdit,
      value: informe.notificarPorMail,
      valid: true,
      touched: false,
      errorMessage: "",
      style: styleSwitch,
      labelPlacement: 'top'
    },
    habilitada: {
      elementType: "switch",
      name: "habilitada",
      label: "Habilitado ***",
      elementConfig: {
        type: "switch",
      },
      validation: {},
      disabled: !canEdit || !pruebaSuccess || informe.habilitada,
      value: informe.habilitar,
      valid: true,
      touched: false,
      errorMessage: "",
      style: styleSwitch,
      labelPlacement: 'top'
    },
  });
  const idEmpresa = controls.empresa && controls.empresa.value && controls.empresa.value.id;

  const isValidDestinatarios = () => {
    const notificarPorMail = controls.notificarPorMail.value;
    const destIsValid = notificarPorMail
      ? destinatariosEmail && destinatariosEmail.length > 0 && destinatariosApp && destinatariosApp.length > 0
      : destinatariosApp && destinatariosApp.length > 0;
    const message = notificarPorMail ? 'Agregue al menos un destinatario de Email y App' : 'Agregue al menos un destinatario de App';
    return [destIsValid, message];
  }

  const isValidConfiguraciones = () => {
    let isValid = true;
    let message = 'Por favor, verifique completar todas las configuraciones y procesada nuevamente a guardar los cambios.';

    if (configuraciones && configuraciones.length > 0) {
      configuraciones && configuraciones.forEach((config) => {
        if (config.valor === null || config.valor === undefined) {
          isValid = false;
        }
      });
    }

    return [isValid, message];
  }

  const validateForm = (updatedControls, isValid) => {
    const destIsValid = (destinatariosEmail && destinatariosEmail.length > 0) || (destinatariosApp && destinatariosApp.length > 0);
    let formIsValid = isValid;
    for (let formElementKey in updatedControls) {
      if (updatedControls[formElementKey].validation) {
        const [updatedControlValid, updatedControlErrorMessage] = checkValidity(updatedControls[formElementKey].value, updatedControls[formElementKey].validation);
        formIsValid = formIsValid && updatedControlValid;

        if (!updatedControlValid) {
          updatedControls[formElementKey].valid = updatedControlValid;
          updatedControls[formElementKey].errorMessage = updatedControlErrorMessage;
          updatedControls[formElementKey].touched = true;
        }
      }
    }

    formIsValid = formIsValid && destIsValid && pruebaSuccess;

    setControls(updatedControls);

    return formIsValid;
  }

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };

    const value = updatedControl.elementType === "checkbox"
      ? event.target.checked
      : updatedControl.elementType === "select"
        ? event
        : updatedControl.elementType === "switch"
          ? !updatedControl.value
          : event.target.value;

    if (updatedControl.elementType === 'file') {
      updatedControl.file = event.target.files[0];
      updatedControl.value = event.target.files[0].name;
    } else {
      updatedControl.value = value;
    }

    const [isValid, errorMessage] = checkValidity(value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    setFormIsValid(validateForm(updatedControls, isValid));
  };

  let form = customControls(controls, inputValueChangedHandler);

  const onEditarConfiguracionClick = () => {
    setShow(true);
  }

  const onUpdateConfigurationes = (items) => {
    setConfiguraciones(items);
    setShow(false);
    checkIsValid(true);
  }

  const onProbarInformeClick = () => {
    setShowProbar(true);
  }

  const onFrecuenciaInformeClick = () => {
    setShowFrecuencia(true);
  }

  const getDestinatarios = (destinatariosEmail, destinatariosApp) => {
    var notificacionMediosDeContacto = [];

    if (destinatariosEmail && destinatariosEmail.length > 0) {
      destinatariosEmail.forEach((dest) => {
        notificacionMediosDeContacto.push({
          usuarioId: dest.id,
          enviarNotificacionMail: true,
          enviarNotificacionApp: false
        });
      });
    }

    if (notificacionMediosDeContacto && notificacionMediosDeContacto.length > 0) {
      notificacionMediosDeContacto.forEach((notif) => {
        var destApp = destinatariosApp && destinatariosApp.find((dest) => dest.id === notif.usuarioId);

        if (destApp)
          notif.enviarNotificacionApp = true;
      });
    }

    if (destinatariosApp && destinatariosApp.length > 0) {
      destinatariosApp.forEach((dest) => {
        var destEmail = notificacionMediosDeContacto.find((notif) => notif.usuarioId === dest.id);

        if (!destEmail) {
          notificacionMediosDeContacto.push({
            usuarioId: dest.id,
            enviarNotificacionMail: false,
            enviarNotificacionApp: true
          });
        }
      });
    }

    return notificacionMediosDeContacto;
  }

  const getConfiguracionesDefault = (configuraciones) => {
    var configuracionesDefault = [];

    configuraciones && configuraciones.forEach((config) => {
      var configDefault = {
        idConfiguracion: config.idConfiguracion ? config.idConfiguracion : config.id,
        valor: config.valor
      };
      configuracionesDefault.push(configDefault);
    });

    return configuracionesDefault;
  }

  const getMonedaId = (configuraciones) => {
    var item = configuraciones && configuraciones.find((config) => config.campo === "id_moneda");
    return item ? item.valor : storageMonedaId();
  }

  const onCrearInforme = (informe) => {
    let tempInforme = { ...informe };

    tempInforme.tituloInforme = controls.tituloInforme.value;
    tempInforme.descripcionInforme = controls.descripcionInforme.value;
    tempInforme.usuarioId = controls.responsable.value ? controls.responsable.value.id : "";
    tempInforme.idEmpresa = controls.empresa.value ? controls.empresa.value.id : 0;
    tempInforme.notificarPorMail = controls.notificarPorMail.value;
    tempInforme.habilitada = controls.habilitada.value;
    tempInforme.configuracionesDefault = getConfiguracionesDefault(configuraciones);
    tempInforme.cronExpression = cronExpression;
    tempInforme.idMoneda = getMonedaId(configuraciones);

    var notificacionMediosDeContacto = getDestinatarios(destinatariosEmail, destinatariosApp);
    tempInforme.notificacionMediosDeContacto = notificacionMediosDeContacto;

    const onCrearInformeSuccess = () => {
      dispatch(closeInformeModal());
      dispatch(closeInformeDisponibleModal());
      dispatch(loadInformesConfigurados(storageUsuarioId()));
      dispatch(loadInformesDisponibles(storageUsuarioId()));
      dispatch(enqueueSnackbar({
        message: 'Informe generado con éxito!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      }));
    }

    const onCrearInformeError = () => {
      dispatch(enqueueSnackbar({
        message: "Error al crear el informe.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
      );
    }

    dispatch(crearInforme({ informe: tempInforme, onSuccess: onCrearInformeSuccess, onError: onCrearInformeError }));
  }

  const onUpdateInforme = (informe) => {
    var notificacionMediosDeContacto = getDestinatarios(destinatariosEmail, destinatariosApp);
    var configuracionesDefault = getConfiguracionesDefault(configuraciones);
    var idMoneda = getMonedaId(configuraciones);
    let tempInforme = {
      id: informe.id,
      idTipoInforme: informe.tipoInforme ? informe.tipoInforme.id : informe.idTipoInforme,
      idEmpresa: controls.empresa.value ? controls.empresa.value.id : 0,
      notificacionMediosDeContacto: notificacionMediosDeContacto,
      tituloInforme: controls.tituloInforme.value,
      descripcionInforme: controls.descripcionInforme.value,
      habilitada: controls.habilitada.value,
      usuarioId: controls.responsable.value ? controls.responsable.value.id : "",
      configuracionesDefault,
      cronExpression: cronExpression && cronExpression !== '' ? cronExpression : detalleInforme ? detalleInforme.cronExpression : '',
      idMoneda
    };

    const onActualizarInformeSuccess = () => {
      dispatch(closeInformeModal());
      dispatch(loadInformesConfigurados(storageUsuarioId()));
      dispatch(loadInformesDisponibles(storageUsuarioId()));
      dispatch(enqueueSnackbar({
        message: 'Informe actualizada con éxito!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "success",
        },
      }));
    }

    const onActualizarInformeError = () => {
      dispatch(enqueueSnackbar({
        message: "Error al actualizar la informe.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "error",
        },
      })
      );
    }

    dispatch(actualizarInforme({ informe: tempInforme, onSuccess: onActualizarInformeSuccess, onError: onActualizarInformeError }));
  }

  const checkIsValid = (isValid) => {
    const updatedControls = { ...controls };
    isValid = validateForm(updatedControls, isValid);
    setFormIsValid(isValid);
    return isValid;
  }

  const onGuardarCambiosClick = () => {
    let isValid = isValidConfiguraciones()[0];

    if (isValid) {
      isValid = checkIsValid(isValid);

      if (isValid) {
        if (informe.id === 0) {
          const puedeConfigurar = informe && informe.tipoInforme
            && informe.tipoInforme.cantidadCreacionesInforme < informe.tipoInforme.cantidadConfiguracionesInforme;

          if (puedeConfigurar) {
            onCrearInforme(informe);
          } else {
            dispatch(enqueueSnackbar({
              message: "Se ha alcanzado el número máximo de configuraciones permitidas.",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            }));
          }
        } else {
          onUpdateInforme(informe);
        }
      } else {
        dispatch(enqueueSnackbar({
          message: isValidConfiguraciones()[1],
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
          },
        }));
      }
    }
  }

  useEffect(() => {
    if (informeId)
      dispatch(loadDetalleInforme(informeId));
  }, [informeId, dispatch]);

  const onSuccess = (data) => {
    setConfiguraciones(data);
  }

  useEffect(() => {
    if (!loadingConfiguraciones && !configuraciones && informe.id > 0) {
      dispatch(loadConfiguracionesDefault({ idInforme: informe.id, onSuccess }));
    }
  }, [loadingConfiguraciones, configuraciones, informe, dispatch]);

  useEffect(() => {
    if (!loadingConfiguraciones && !configuraciones && informe.id === 0 && idEmpresa) {
      dispatch(loadConfiguracionesDefaultPorTipo({ idTipoInforme: informe.idTipoInforme, idEmpresa, onSuccess }));
    }
  }, [loadingConfiguraciones, configuraciones, informe, idEmpresa, dispatch]);

  useEffect(() => {
    if(!cronExpression) {
      if(informe && informe.cronExpression) {
        setCronExpression(informe.cronExpression);
      } else {
        if(detalleInforme && detalleInforme.cronExpression) {
          setCronExpression(detalleInforme.cronExpression);
        } else {
          setCronExpression('');
        }
      }
    }
  }, [cronExpression, informe, detalleInforme]);

  const onUpdateDestinatariosEmail = (destinatarios) => {
    setDestinatariosEmail(destinatarios);
    let isValid = destinatariosEmail && destinatariosEmail.length > 0;
    checkIsValid(isValid);
  }

  const onUpdateDestinatariosApp = (destinatarios) => {
    setDestinatariosApp(destinatarios);
    let isValid = destinatariosApp && destinatariosApp.length > 0;
    checkIsValid(isValid);
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
            {form[0]}
          </Grid>
          <Grid item xs={6}>
            {form[1]}
          </Grid>
          <Grid item xs={6} lg={3} style={{ paddingTop: '0px' }}>
            {form[2]}
          </Grid>
          <Grid item xs={6} lg={3} style={{ paddingTop: '0px' }}>
            {form[3]}
          </Grid>
          <Grid item xs={6} lg={3}>
            {form[4]}
          </Grid>
          <Grid item xs={6} lg={3}>
            {form[5]}
          </Grid>
          <Grid item xs={6} container spacing={1}>
            <InformeDestinatariosApp
              title="Destinatarios Email"
              destinatarios={destinatariosEmail}
              setDestinatarios={onUpdateDestinatariosEmail}
              usuarios={usuariosWeb}
              disabled={!canEdit}
            />
          </Grid>
          <Grid item xs={6} container spacing={1}>
            <InformeDestinatariosApp
              title="Destinatarios App"
              destinatarios={destinatariosApp}
              setDestinatarios={onUpdateDestinatariosApp}
              usuarios={usuariosApp}
              disabled={!canEdit}
            />
          </Grid>
        </Grid>
        <Divider sx={{ width: '100%' }} />
        <Grid item xs={12} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={12} lg={6}>
            <Typography component='div' style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: 'x-small' }}>* Campo requerido.</span>
              <span style={{ fontSize: 'x-small' }}>** Seleccione una empresa para poder editar las configuraciones del informe.</span>
              <span style={{ fontSize: 'x-small' }}>*** El informe se podrá habilitar cuando se realice una prueba exitosa del mismo.</span>
              {!isValidDestinatarios()[0] && (<span style={{ fontSize: 'x-small', color: 'red' }}>{isValidDestinatarios()[1]}</span>)}
              {!isValidConfiguraciones()[0] && (<span style={{ fontSize: 'small', color: 'red' }}>{isValidConfiguraciones()[1]}</span>)}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} container spacing={1} justifyContent='flex-end' alignContent='center'>
            <Grid item xs={3}>
              <Button
                disabled={!canEdit}
                onClick={() => onFrecuenciaInformeClick()}
                color='info'
                variant='contained'
                fullWidth={true}
                style={{ paddingInline: '15px', color: 'white', fontSize: "12px" }} >
                Frecuencia
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                disabled={!canEdit}
                onClick={() => onProbarInformeClick()}
                color='info'
                variant='contained'
                fullWidth={true}
                style={{ paddingInline: '15px', color: 'white', fontSize: "12px" }} >
                Probar
              </Button>
            </Grid>
            {configuraciones && configuraciones.length > 0 && (<Grid item xs={3}>
              <Button
                disabled={!canEdit || !controls.empresa.value}
                onClick={() => onEditarConfiguracionClick()}
                color='info'
                variant='contained'
                fullWidth={true}
                style={{ paddingInline: '15px', color: 'white', fontSize: "12px" }} >
                Configurar
              </Button>
            </Grid>)}
            <Grid item xs={3}>
              <Button
                disabled={!canEdit}
                onClick={() => onGuardarCambiosClick()}
                color='primary'
                variant='contained'
                fullWidth={true}
                style={{ paddingInline: '15px', color: 'white', fontSize: "12px" }} >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        title={(
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">Configuraciones</Typography>
              <IconButton aria-label="close" onClick={() => setShow(false)} >
                <Close />
              </IconButton>
            </div>
          </div>
        )}
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: '20px 40px 20px 40px'
        }}
        maxWidth='lg'
        show={show}
        body={(
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            <InformeConfiguraciones
              informe={informe}
              canEdit={canEdit}
              loading={loadingConfiguraciones}
              configuraciones={configuraciones}
              setConfiguraciones={setConfiguraciones}
              updateConfiguraciones={onUpdateConfigurationes}
              idEmpresa={controls.empresa && controls.empresa.value && controls.empresa.value.id}
            />
          </div>
        )}
        onClose={() => setShow(false)}
      />
      <Dialog
        title={(
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">Probar Informe</Typography>
              <IconButton aria-label="close" onClick={() => setShowProbar(false)} >
                <Close />
              </IconButton>
            </div>
          </div>
        )}
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: '20px 40px 20px 40px'
        }}
        maxWidth='lg'
        show={showProbar}
        body={(
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            <InformeProbar
              informe={informe}
              canEdit={canEdit}
              onSuccess={() => setPruebaSuccess(true)}
              onError={() => setPruebaSuccess(false)}
            />
          </div>
        )}
        onClose={() => setShowProbar(false)}
      />
      <Dialog
        title={(
          <div classes={classes.ModalTitleContent} style={{ width: "100%" }}>
            <div className={classes.ModalTitleTexto}>
              <Typography variant="h5">Frecuencia</Typography>
              <IconButton aria-label="close" onClick={() => setShowFrecuencia(false)} >
                <Close />
              </IconButton>
            </div>
          </div>
        )}
        fullWidth={false}
        fullScreen={false}
        styleContent={{
          padding: '20px 40px 20px 40px'
        }}
        maxWidth='lg'
        show={showFrecuencia}
        body={(
          <div
            className={classes.ModalBodyDialog}
            style={props.loadingBodyDialog ? { display: "flex", justifyContent: "center" } : undefined}
          >
            <InformeFrecuencia
              cronExpression={cronExpression}
              setCronExpression={setCronExpression}
              canEdit={canEdit}
              setShowFrecuencia={setShowFrecuencia}
            />
          </div>
        )}
        onClose={() => setShowProbar(false)}
      />
    </Fragment>
  )
}

const InformeDestinatariosApp = (props) => {
  const { title, destinatarios, setDestinatarios, usuarios, disabled } = props;

  const [newDestinatario, setNewDestinatario] = useState('');
  const [newDestinatarioError, setNewDestinatarioError] = useState(false);
  const [newDestinatarioErrorMessage, setNewDestinatarioErrorMessage] = useState('Campo requerido');
  const [addDestinatario, setAddDestinatario] = useState(false);

  const onConfirmAddDestinatario = () => {
    if (newDestinatario !== '') {
      setNewDestinatarioError(false);
      const usuarioExistente = destinatarios.find((d) => d.id === newDestinatario);

      if (usuarioExistente) {
        setNewDestinatarioError(true);
        setNewDestinatarioErrorMessage('Usuario existente');
        return;
      }

      const selectedUser = usuarios.find((u) => u.id === newDestinatario);

      if (selectedUser) {
        let tempDestinatarios = [].concat(destinatarios);
        tempDestinatarios.push(selectedUser);
        setDestinatarios(tempDestinatarios);
        clearAddDestinatario();
      }
      setAddDestinatario(false);
    }
    else {
      setNewDestinatarioError(true);
    }
  }

  const onCancelAddDestinatario = () => {
    clearAddDestinatario();
    setAddDestinatario(false);
  }

  const handleDelete = (usuario) => {
    if (disabled)
      return;

    const filterDestinatarios = destinatarios.filter((u) => u.id !== usuario.id);

    if (filterDestinatarios)
      setDestinatarios(filterDestinatarios);
  }

  const clearAddDestinatario = () => {
    setNewDestinatario('');
    setNewDestinatarioError(false);
    setNewDestinatarioErrorMessage('Campo requerido');
  }

  return (
    <Fragment>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <Tooltip title="Agregar destinatario">
          <IconButton
            style={{ width: '25px', height: '25px' }}
            onClick={() => disabled ? undefined : setAddDestinatario(true)}
          >
            <AddOutlined />
          </IconButton>
        </Tooltip>
      </Grid>
      {
        addDestinatario && (
          <Grid item xs={12} container spacing={1} justifyContent='space-between' alignContent='center' style={{ marginBottom: '10px' }}>
            <Grid item xs={6}>
              <FormControl variant="standard" style={{ width: '100%' }}>
                <InputLabel id="Add-Destinatario">Usuario *</InputLabel>
                <Select
                  labelId="Add-Destinatario"
                  id="Add-Destinatario"
                  value={newDestinatario}
                  onChange={(event) => setNewDestinatario(event.target.value)}
                  required={true}
                  style={{ fontSize: 'small' }}
                  error={newDestinatarioError}
                >
                  {usuarios && usuarios.map((user, index) => (
                    <MenuItem
                      key={index}
                      value={user.id}
                      style={{ fontSize: 'small' }}>
                      {user.nombre + ' ' + user.apellido}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={newDestinatarioError} sx={{ color: 'red' }}>{newDestinatarioErrorMessage}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Tooltip title="Confirmar">
                <IconButton onClick={onConfirmAddDestinatario}>
                  <CheckOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancelar">
                <IconButton onClick={onCancelAddDestinatario}>
                  <CloseOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <EmailContainer>
          <List dense={true} style={{ paddingTop: '0px' }}>
            {destinatarios && destinatarios.map((usuario, index) => (
              <Chip
                key={index}
                label={usuario.nombre + ' ' + usuario.apellido}
                variant="outlined"
                onDelete={() => handleDelete(usuario)}
                style={{ margin: '1px' }}
              />
            ))}
          </List>
        </EmailContainer>
      </Grid>
    </Fragment>
  )
}

const InformeConfiguraciones = (props) => {
  const { canEdit, loading, configuraciones, setConfiguraciones, updateConfiguraciones } = props;

  const inputLabelProps = {
    style: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)',
      width: '100%',
    }
  }

  const onUpdateConfiguration = (config) => {
    var tempConfigs = [];
    configuraciones.forEach((c) => {
      var tempConfig = { ...c };
      if ((tempConfig.id === 0 && tempConfig.idConfiguracion === config.idConfiguracion) || (tempConfig.id !== 0 && tempConfig.id === config.id)) {
        tempConfig.valor = config.valor;
      }

      tempConfigs.push(tempConfig);
    });

    setConfiguraciones(tempConfigs);
  }

  const renderConfiguracion = (config) => {
    var tempCampo = (config.campo ? config.campo : config.display.trim());
    var campo = tempCampo.split(".").length > 1
      ? tempCampo.split(".")[1]
      : tempCampo.split(".")[0];

    let elem = {
      name: campo,
      label: config.display,
      placeholder: config.display,
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: false,
      value: config.valor ? config.valor : '',
      validation: {},
      valid: true,
      touched: false,
      errorMessage: "",
    };

    const onChangeHandler = (event, inputId) => {
      let value = elem.elementType === "checkbox"
        ? event.target.checked
        : elem.elementType === "select"
          ? event
          : elem.elementType === "switch"
            ? !elem.value
            : event.target.value;

      if (config.tipoUI === "Dropdown") {
        value = value[campo];
      }

      if (config.tipoUI === "Dropdown Multiselect") {
        if (value && value.length > 0) {
          let tempValue = `("${value.map((item) => item[campo]).join()}")`;
          value = tempValue;
        }
      }

      elem.value = value;

      var tempConfig = { ...config };
      tempConfig.valor = value;

      onUpdateConfiguration(tempConfig);
    }

    if (config.tipoUI === "TextBox") {
      return customInput(elem, onChangeHandler);
    } else if (config.tipoUI === "CheckBox") {
      elem.elementConfig.type = "checkbox";
      return customCheckbox(elem, onChangeHandler);
    } else if (config.tipoUI === "Dropdown") {
      elem.elementType = 'select';
      elem.elementConfig.type = 'select';
      elem.multiple = false;
      elem['autocompleteInputTop'] = true;

      var value = config.valor;

      if (typeof config.valor === 'string') {
        var tempValue = value.trimEnd();

        if (config.data) {
          config.data.forEach((item) => {
            var itemValue = item[campo];
            if (itemValue && itemValue.trimEnd() === tempValue)
              value = item;
          });
        }
      }

      elem.value = value;
      elem.items = config.data;
      return customSelect(elem, onChangeHandler);
    } else if (config.tipoUI === "DateTime") {
      elem.elementConfig.type = "datetime";
      return customInput(elem, onChangeHandler);
    } else if (config.tipoUI === "TextBox number") {
      elem.elementConfig.type = "number";
      return customInput(elem, onChangeHandler);
    } else if (config.tipoUI === "Dropdown Multiselect") {
      elem.elementType = 'select';
      elem.elementConfig.type = 'select';
      elem.multiple = true;

      var multiSelectValue = [];
      if (config.valor && typeof config.valor === 'string' && config.valor !== "") {
        var stringValue = config.valor;
        stringValue = stringValue.replace('("', "");
        stringValue = stringValue.replace('")', "");

        if (stringValue && stringValue !== "" && config.data && config.data.length > 0) {
          stringValue.split(",").forEach((stringItem) => {
            config.data.forEach((item) => {
              var itemValue = item[campo];
              if (itemValue && itemValue.trimEnd() === stringItem)
                multiSelectValue.push(item);
            });
          })
        }
      }

      elem.value = multiSelectValue;
      elem.items = config.data;
      return customSelect(elem, onChangeHandler);
    } else {
      return customInput(elem, onChangeHandler);
    }
  }

  const onGuardarCambiosClick = () => {
    updateConfiguraciones(configuraciones);
  }

  return loading
    ? (
      <Spinner />
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={12} container spacing={1} sx={{ minHeight: '220px', width: '550px' }}>
          {configuraciones && configuraciones.map((config, index) => (
            <Grid item xs={config.tipoUI === "Dropdown Multiselect" ? 12 : 4} key={index}>
              {renderConfiguracion(config)}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={6} lg={3}>
            <Button disabled={!canEdit || !configuraciones || configuraciones.length === 0}
              onClick={() => onGuardarCambiosClick()}
              color='primary' variant='contained'
              style={{ paddingInline: '15px', color: 'white' }} >
              Guardar cambios
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
}

const InformeProbar = (props) => {
  const dispatch = useDispatch();
  const { informe, onSuccess, onError } = props;
  const ejecutando = useSelector(state => state.informes.ejecutarInforme.ejecutando);
  const ejecutada = useSelector(state => state.informes.ejecutarInforme.ejecutada);
  const error = useSelector(state => state.informes.ejecutarInforme.error);

  const renderEjecutada = () => {
    onSuccess();

    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            Informe ejecutado correctamente.
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeInformeModal())}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Aceptar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const renderError = () => {
    onError();

    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            {'Ha ocurrido un error al ejecutar el informe' + (informe ? ` ${informe.tituloInforme}` : '') + '. Desea reintentar nuevamente?'}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(ejecutarInforme(informe.id))}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Ejecutar
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeInformeModal())}
              className={classes.ModalButton}
              variant="contained"
              style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  const renderEjecutar = () => {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Typography style={{ textAlign: 'start' }}>
            {'Ejecución de prueba del informe' + (informe ? ` ${informe.tituloInforme}` : '') + '.'}<br />
            Recuerde que la notificación de esta prueba solo se enviará al usuario que la ejecute y que la prueba debe ser exitosa para poder habilitar el informe.<br />
            {'¿Confirma la ejecución del informe' + (informe ? ` ${informe.tituloInforme}` : '') + '?'}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={1} justifyContent='center'>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(ejecutarInforme(informe.id))}
              className={classes.ModalButton}
              variant="contained"
              color="primary">
              Ejecutar
            </Button>
          </Grid>
          <Grid item xs={6} lg={3}>
            <Button
              onClick={() => dispatch(closeInformeModal())}
              className={classes.ModalButton}
              variant="contained"
              style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)" }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return (
    <Grid container spacing={1} direction='column' justifyContent='center' alignContent='center' style={{ minWidth: '550px', minHeight: '150px' }}>
      {ejecutando ? (
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <Spinner />
        </Grid>
      ) : ejecutada
        ? error ? renderError() : renderEjecutada()
        : renderEjecutar()
      }
    </Grid>
  )
}

export default InformeConfiguradoDetalle;