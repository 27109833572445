import React, { useRef } from 'react';

const IframePortal = () => {
  const iframeRef = useRef();

  return (
    <div>
      <iframe
        ref={iframeRef}
        src={process.env.REACT_APP_PORTAL_URL}
        id="helperFrame"
        title="portal"
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default IframePortal;