import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { urlPortalLogout } from '../../../assets/shared/urlApps';
import { logout } from '../../../store/slices/authSlice';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return setTimeout(() => urlPortalLogout(), 500);
};

export default Logout;
