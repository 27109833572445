import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadIndicadores } from '../../../store/slices/configuracionesSlice';

import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton, Typography } from '@mui/material';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import Spinner from '../../UI/Spinner/Spinner';

const IndicadoresMultiempresaConfig = (props) => {
  const dispatch = useDispatch();
  const indicadores = useSelector(state => state.configuraciones.indicadoresPorUsuario.items);
  const loading = useSelector(state => state.configuraciones.indicadoresPorUsuario.loading);
  const onLoad = () => dispatch(loadIndicadores());
  const { onChangeValue, seleccionarTodos, deseleccionarTodos } = props;

  const [seleccionarTodoCheck, setSeleccionarTodoCheck] = useState(null);
  const [indicadoresLocal, setIndicadoresLocal] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);

  useEffect(() => {
    setIndicadoresLocal(indicadores.filter((ind) => ind.esMultiempresa === true));
    return () => setIndicadoresLocal([]);
  }, [indicadores, loading]);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    enviarValoresTrue(indicadores);
  }, [loading]);

  const enviarValoresTrue = (indicadores) => {
    var arr = [];
    indicadores.map((indicador) => {
      if (indicador.estaOculto) {
        return arr.push(indicador);
      }
      return undefined;
    });

    props.configInicial(arr);
  };

  const onSeleccionarTodosClick = () => {
    setLoadingLocal(true);

    var formatIndicadoresFalse = [];
    seleccionarTodos();
    setSeleccionarTodoCheck(false);
    indicadoresLocal.map((indicador) => {
      return formatIndicadoresFalse.push({ ...indicador, estaOculto: false });
    });
    setIndicadoresLocal(formatIndicadoresFalse);
    setTimeout(() => setLoadingLocal(false), 500);
  };

  const onDeseleccionarTodosClick = () => {
    setLoadingLocal(true);
    var arr = [];
    var formatIndicadoresTrue = [];
    indicadores.map((indicador) => {
      if (indicador.esMultiempresa === true) {
        arr.push(indicador);
        return formatIndicadoresTrue.push({ ...indicador, estaOculto: true });
      } else {
        return undefined;
      }
    });
    setSeleccionarTodoCheck(true);
    deseleccionarTodos(arr);
    setIndicadoresLocal(formatIndicadoresTrue);
    setTimeout(() => setLoadingLocal(false), 500);
  };

  const buildSeleccionarTodoButton = () => {
    const verifyTodoSeleccionado = indicadoresLocal.filter((comp) => comp.estaOculto === false);
    const verifyTodoDeseleccionado = indicadoresLocal.filter((comp) => comp.estaOculto === true);
    const verifySeleccionadoMayor = verifyTodoSeleccionado.length > verifyTodoDeseleccionado.length;

    return (
      <div>
        <IconButton
          onClick={
            seleccionarTodoCheck === null
              ? verifyTodoSeleccionado.length === indicadoresLocal.length
                ? () => onDeseleccionarTodosClick()
                : verifyTodoDeseleccionado.length === indicadoresLocal.length
                ? () => onSeleccionarTodosClick()
                : verifySeleccionadoMayor
                ? () => onDeseleccionarTodosClick()
                : () => onSeleccionarTodosClick()
              : seleccionarTodoCheck
              ? () => onSeleccionarTodosClick()
              : () => onDeseleccionarTodosClick()
          }
        >
          <SelectAllIcon style={{ color: seleccionarTodoCheck && 'rgb(9, 212, 255)' }} />
        </IconButton>
        <Typography variant="button">
          {seleccionarTodoCheck === null
            ? verifyTodoSeleccionado.length === indicadoresLocal.length
              ? 'Deseleccionar todos'
              : verifyTodoDeseleccionado.length === indicadoresLocal.length
              ? 'Seleccionar todos'
              : verifySeleccionadoMayor
              ? 'Deseleccionar todos'
              : 'Seleccionar todos'
            : seleccionarTodoCheck
            ? 'Seleccionar todos'
            : 'Deseleccionar todos'}
        </Typography>
      </div>
    );
  };

  const formateoIndicadores = () => {
    const formateoIndicadores = [];
    for (var i = 0; i <= indicadoresLocal.length / 12; i++) {
      formateoIndicadores.push(indicadoresLocal.slice(i * 12, i * 12 + 12));
    }

    return (
      <div style={{ display: 'flex' }}>
        {formateoIndicadores.map((indicadores, key) => (
          <div key={key} style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            {indicadores.map((indicador, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch key={key} defaultChecked={!indicador.estaOculto} onChange={() => onChangeValue(indicador)} />
                }
                label={indicador.nombre.trim()}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={{ minHeight: loading || loadingLocal ? '205px' : '0px' }}>
      {!loading && !loadingLocal && buildSeleccionarTodoButton()}
      {loading || loadingLocal ? (
        <Spinner />
      ) : (
        <FormControl component="fieldset">
          <FormGroup>{formateoIndicadores()}</FormGroup>
        </FormControl>
      )}
    </div>
  );
};

export default IndicadoresMultiempresaConfig;