import React, { useState } from 'react';
import { IconButton, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '../../UI/Dialog/Dialog';
import Buton from "../Button/Button";
import { urlPortalLogout } from '../../../assets/shared/urlApps';
import classes from './BackButtonModal.module.css';
import { showBackButtonModal } from "../../../store/slices/modalsSlice";

export const BackButtonModal = () => {
  const dispatch = useDispatch();
  const backButton = useSelector(state => state.modals.backButtonModal);
  const [title] = useState("");
  const [message] = useState("¿Desea cerrar sesión en la aplicación?");

  const onLogoutClick = () => {
    sessionStorage.clear();
    return setTimeout(() => urlPortalLogout(), 100);
  };

  const onHiddenModal = () => {
    dispatch(showBackButtonModal(false));
  }

  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h6">{title}</Typography>
            <div>
              <IconButton aria-label="close" onClick={() => onHiddenModal()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      }
      show={backButton.show}
      body={
        <Grid container spacing={1}>
          <Grid item
            container 
            spacing={1}
            direction='row'
            justify='center'
            alignItems='center'>
            <Grid item>
              <Typography variant="h5">{message}</Typography>
            </Grid>
          </Grid>
          <Grid item 
            container 
            spacing={1}
            direction='row'
            justify='center'
            alignItems='center'
            style={{ maxWidth: 'calc(100% - 8px)' }}
          >
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Buton onClick={() => onLogoutClick()} style={{ width:'100%', marginInline:'10px' }}>
                Confirmar
              </Buton>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Buton onClick={() => onHiddenModal()} style={{ width:'100%', marginInline:'10px', backgroundColor: "red", opacity: 0.5 }}>
                Cancelar
              </Buton>
            </Grid>
          </Grid>
        </Grid>
      }
      onClose={() => {}}
    />
  );
};

export default BackButtonModal;
