import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './Alertas.module.css';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  NotificationsActiveOutlined, NotificationsOffOutlined, EditOutlined, HistoryEduOutlined,
  ContentCopyOutlined, PlayCircleOutlined, DeleteOutlineOutlined
} from '@mui/icons-material';
import {
  habilitarAlerta, deshabilitarAlerta, loadAlertasConfiguradas,
  openCopiarAlertaModal, openActualizarAlertaModal, openHistorialAlertaModal,
  openEjecutarAlertaModal, clearEjecutarAlerta, openEliminarAlertaModal,
  clearEliminarAlerta
} from '../../../../store/slices/alertasSlice';
import { storageUsuarioId } from '../../../../assets/shared/sessionData';
import moment from 'moment';
import { enqueueSnackbar } from '../../../../store/slices/snackbarSlice';
import { loadAlertasDisponibles } from '../../../../store/slices/tipoAlertaSlice';

const AlertaConfiguradaCard = (props) => {
  const dispatch = useDispatch();
  const { alerta } = props;

  const onHabilitarAlerta = (alerta) => {
    const puedeConfigurar = alerta && alerta.tipoAlerta
      && alerta.tipoAlerta.cantidadConfiguraciones < alerta.tipoAlerta.cantidadMaxConfiguraciones;

    if (puedeConfigurar) {
      dispatch(habilitarAlerta(alerta.id));
      dispatch(loadAlertasConfiguradas(storageUsuarioId()));
      dispatch(loadAlertasDisponibles(storageUsuarioId()));
    } else {
      dispatch(enqueueSnackbar({
        message: "Se ha alcanzado el número máximo de configuraciones permitidas.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
        },
      }));
    }
  }

  const onDeshabilitarAlerta = (alerta) => {
    dispatch(deshabilitarAlerta(alerta.id));
    dispatch(loadAlertasConfiguradas(storageUsuarioId()));
    dispatch(loadAlertasDisponibles(storageUsuarioId()));
  }

  const onEjecutarAlerta = (alerta) => {
    if(alerta.habilitada) {
      dispatch(clearEjecutarAlerta());
      dispatch(openEjecutarAlertaModal(alerta));
    } else {
      dispatch(enqueueSnackbar({
        message: "La alerta se encuentra deshabilitada, no se puede ejecutar manualmente.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
        },
      }));
    }
  }

  const onEliminarAlerta = (alerta) => {
    dispatch(clearEliminarAlerta());
    dispatch(openEliminarAlertaModal(alerta));
  }

  const onCopiarAlerta = (alerta) => {
    const puedeConfigurar = alerta && alerta.tipoAlerta
      && alerta.tipoAlerta.cantidadConfiguraciones < alerta.tipoAlerta.cantidadMaxConfiguraciones;

    if (puedeConfigurar) {
      dispatch(openCopiarAlertaModal(alerta));
    } else {
      dispatch(enqueueSnackbar({
        message: "Se ha alcanzado el número máximo de configuraciones permitidas.",
        options: {
          key: new Date().getTime() + Math.random(),
          variant: "warning",
        },
      }));
    }
  }

  return (
    <Grid item xs={12} lg={6} container style={{ maxWidth: '95%' }}>
      <Grid item xs={12} container className={classes.ItemCard}>
        <Grid item xs={8} container spacing={1}>
          <Grid item xs={12}>
            <Typography>
              <strong style={{ fontSize: 'small' }}>Alerta: </strong> <span>{alerta && alerta.tituloAlerta}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Empresa: </strong><br />
                <span>{alerta && alerta.empresa && alerta.empresa.nombre}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Propietario: </strong><br />
                <span>{alerta && alerta.usuario && alerta.usuario.nombreApellido}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontSize: 'small' }}>
                <strong>Última ejecución: </strong><br />
                <span>{alerta && alerta.ultimaEjecucion ? moment(alerta.ultimaEjecucion).format('HH:mm DD/MM/yyyy') : ''}</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container spacing={1} justifyContent='flex-end' alignContent='center'>
          <Grid item xs={2}>
            {alerta.habilitada ? (
              <Tooltip title="Deshabilitar alerta">
                <IconButton onClick={() => onDeshabilitarAlerta(alerta)}>
                  <NotificationsActiveOutlined htmlColor='#F46B2C' />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Habilitar alerta">
                <IconButton onClick={() => onHabilitarAlerta(alerta)}>
                  <NotificationsOffOutlined htmlColor='#515151' />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Editar alerta">
              <IconButton onClick={() => dispatch(openActualizarAlertaModal(alerta))}>
                <EditOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Eliminar alerta">
              <IconButton onClick={() => onEliminarAlerta(alerta)}>
                <DeleteOutlineOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Historial de ejecuciones">
              <IconButton onClick={() => dispatch(openHistorialAlertaModal(alerta))}>
                <HistoryEduOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Copiar alerta">
              <IconButton onClick={() => onCopiarAlerta(alerta)}>
                <ContentCopyOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Ejecutar alerta manualmente">
              <IconButton onClick={() => onEjecutarAlerta(alerta)}>
                <PlayCircleOutlined htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AlertaConfiguradaCard;