import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";
import { storageUserEmail } from '../../assets/shared/sessionData';
import { urlPortalLogout } from '../../assets/shared/urlApps';

import { useDispatch, useSelector } from 'react-redux';
import { showGlobalModal } from '../../store/slices/modalsSlice';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const listItems = [
  {
    id: 'logout',
    title: 'Cerrar sesión'
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
}));

const MenuUsuarioMobile = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const onLogoutClick = () => {
    sessionStorage.clear();
    return setTimeout(() => urlPortalLogout(), 100);
  };

  const onItemClick = (selectedItem) => {
    if (selectedItem.id === 'logout') {
      return onLogoutClick();
    } else {
      return onShowModal(
        !selectedItem.hiddenTitle && selectedItem.title,
        selectedItem.body,
        selectedItem.fullWidth,
        selectedItem.maxWidth
      );
    }
  };

  const onShowModal = (title, body, fullWidth, maxWidth) => {
    dispatch(showGlobalModal(true, title, body, undefined, false, fullWidth, maxWidth));
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick}>
        <ListItemText primary={storageUserEmail()} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems.map((item, key) => (
            <ListItem 
              key={key} 
              button 
              className={classes.nested} 
              onClick={(event) => onItemClick(item)}
              alignItems={'flex-start'}
              divider              
            >
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  );
};

export default MenuUsuarioMobile;