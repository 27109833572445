import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import classes from './AdministracionInformes.module.css';
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";
import FullWidthTabs from "../../components/UI/FullWidthTabs/FullWidthTabs";
import { loadEmpresasDelUsuario } from "../../store/slices/multiempresaSlice";
import { storageClienteId, storageUsuarioId } from "../../assets/shared/sessionData";
import { loadUsuarios } from "../../store/slices/clientesSlice";
import InformeConfiguradoList from "../../components/UI/Informes/InformesConfigurados/InformeConfiguradoList";
import InformeDisponibleList from "../../components/UI/Informes/InformesDisponibles/InformeDisponibleList";
import { loadFrecuencias } from "../../store/slices/informesSlice";

const AdministracionAlertas = () => {
  const dispatch = useDispatch();
  sessionStorage.setItem('seccionNombre', 'Administración Informes');
  const ejecucionDePagina = useSelector(state => state.ejecucionPaginas.ejecucionAdministracionInformes);
  const loadingEmpresas = useSelector(state => state.multiempresa.empresasDelUsuario.loading);
  const estaCargadoEmpresas = useSelector(state => state.multiempresa.empresasDelUsuario.estaCargado);
  const loadingUsuarios = useSelector(state => state.clientes.usuarios.loading);
  const estaCargadoUsuarios = useSelector(state => state.clientes.usuarios.estaCargado);
  const loadingFrecuencias = useSelector(state => state.informes.frecuencias.loading);
  const estaCargadoFrecuencias = useSelector(state => state.informes.frecuencias.estaCargado);

  useEffect(() => {
    if (!loadingEmpresas && !estaCargadoEmpresas)
      dispatch(loadEmpresasDelUsuario(storageUsuarioId()));
  }, [loadingEmpresas, estaCargadoEmpresas, dispatch]);

  useEffect(() => {
    if (!loadingUsuarios && !estaCargadoUsuarios)
      dispatch(loadUsuarios(storageClienteId()));
  }, [loadingUsuarios, estaCargadoUsuarios, dispatch]);

  useEffect(() => {
    if (!loadingFrecuencias && !estaCargadoFrecuencias)
      dispatch(loadFrecuencias());
  }, [loadingFrecuencias, estaCargadoFrecuencias, dispatch]);

  return (
    <ConteinerWithEjecutarPagina isExecuted={!ejecucionDePagina} >
      <FullWidthTabs
        tabs={['Configurados', 'Disponibles']}
        views={[
          <InformeConfiguradoList ejecucionDePagina={ejecucionDePagina} />,
          <InformeDisponibleList ejecucionDePagina={ejecucionDePagina} />
        ]}
        rootClass={classes.TabsRoot}
      />
    </ConteinerWithEjecutarPagina>
  );
};

export default AdministracionAlertas;