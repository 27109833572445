import React from "react";
import { useSelector } from 'react-redux';

import Toolbar from "../../components/Navigation/Drawer/Drawer";
import ModalGlobal from "../../components/UI/ModalGlobal/ModalGlobal";
import Notifier from "../../components/UI/Notifier/Notifier";
import EnviarChart from "../../components/UI/EnviarChart/EnviarChart";
import ModalEstado from "../../components/UI/ModalEstado/ModalEstado";
import BackButton from '../../components/UI/BackButton/BackButton';
import BackButtonModal from '../../components/UI/BackButton/BackButtonModal';
import { storageClienteNombre, storageEmpresaNombre } from "../../assets/shared/sessionData";

const Layout = (props) => {
  const isAuth = useSelector(state => state.auth.token !== null);

  return (
    <React.Fragment>
      <BackButton />
      <Notifier />
      <Toolbar
        isAuth={isAuth}
        nombreEmpresa={storageEmpresaNombre()}
        nombreCliente={storageClienteNombre}
      >
        {props.children}
      </Toolbar>
      <ModalGlobal />
      <EnviarChart />
      <ModalEstado />
      <EnviarChart />
      <BackButtonModal/>
    </React.Fragment>
  );
};

export default Layout;