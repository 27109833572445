import React, { useCallback, useEffect, useState } from 'react';
import { pathNamePages } from '../../assets/shared/pathnames';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import LightTooltip from '../UI/LightTooltip/LightTooltip';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ejecutarAlertas, ejecutarInformes } from '../../store/slices/ejecucionPaginasSlice';
import { useDispatch } from 'react-redux';

const ButtonEjecucionPagina = (props) => {
  const { administracionAlertas, administracionInformes, pruebaNotificaciones } = pathNamePages;  
  const dispatch = useDispatch();
  const ejecucionAlertas= useSelector(state => state.ejecucionPaginas.ejecucionAdministracionAlertas);
  const ejecucionInformes = useSelector(state => state.ejecucionPaginas.ejecucionAdministracionInformes);
  const paginaRender = useSelector(state => state.ejecucionPaginas.paginaRender);
  const ejecutarPaginaAlertas = () => dispatch(ejecutarAlertas());
  const ejecutarPaginaInformes = () => dispatch(ejecutarInformes());
  const [botonOculto, setBotonOculto] = useState(false);
  
  const getPath = () => {
    return global.window.location.pathname;
  }

  const ejecutarPagina = () => {
    const path = getPath();    
    path === administracionAlertas && ejecutarYOcultarBotonLocal(ejecutarPaginaAlertas, administracionAlertas);
    path === administracionInformes && ejecutarYOcultarBotonLocal(ejecutarPaginaInformes, administracionInformes);
  };

  const ejecutarYOcultarBotonLocal = (ejecucionMethod, paginaKey) => {
    const path = global.window.location.pathname;
    path === paginaKey && ejecucionMethod();
    setBotonOculto(true);
  };

  const ocultarBoton = useCallback((pathRender) => {
    (pathRender === administracionAlertas) && setBotonOculto(ejecucionAlertas);
    (pathRender === administracionInformes) && setBotonOculto(ejecucionInformes);
  }, [administracionAlertas, administracionInformes, ejecucionAlertas, ejecucionInformes]);

  useEffect(() => {
    ocultarBoton(paginaRender);
  }, [paginaRender, ocultarBoton]);

  return (
    !botonOculto && getPath() !== pruebaNotificaciones && (
      <LightTooltip
        arrow
        title={
          <Typography style={{ textAlign: 'left', font: 'normal normal normal 14px/19px Roboto', color: '8D8C8C' }}>
            Ejecutar página
          </Typography>
        }
        placement="bottom"
      >
        <PlayCircleFilledWhiteOutlinedIcon
          onClick={() => ejecutarPagina()}
          style={{ color: 'white', fontSize: '1.8rem', cursor: 'pointer', marginRight: '10px' }}
        />
      </LightTooltip>
    )
  );
};

export default ButtonEjecucionPagina;