import React, { Suspense, useEffect, useState, useCallback } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { authCheckState, login } from "./store/slices/authSlice";

import './App.css';

import PantallaCarga from "./containers/PantallaCarga/PantallaCarga";
import Layout from "./containers/Layout/Layout";
import SimpleBackdrop from "./components/UI/Backdrop/Backdrop";
import Login from "./containers/Auth/Login/Login";
import Logout from "./containers/Auth/Logout/Logout";
import IframePortal from "./containers/Auth/IframePortal/IframePortal";

import Spinner from "./components/UI/Spinner/Spinner";
import { storageToken, getUserInfo } from "./assets/shared/sessionData";
import AdministracionAlertas from "./containers/AdministracionAlertas/AdministracionAlertas";
import AdministracionInformes from "./containers/AdministracionInformes/AdministracionInformes";
import TestNotificaciones from "./containers/TestNotificaciones/TestNotificaciones";

const App = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const token = useSelector(state => state.auth.token);
  const loadingAuth = useSelector(state => state.auth.loading);
  const loadingCambiarEmpresa = useSelector(state => state.multiempresa.loadingCambiarEmpresa);
  const loadingCambiarMoneda = useSelector(state => state.monedas.loadingCambiarMoneda);
  const loadingExecuteCambiarAjustaPorInflacion = useSelector(state => state.configuraciones.executeCambiarAjustaPorInflacion);
  const loadingCambiarSucursal = useSelector(state => state.sucursales.loadingCambiarSucursal);
  const userInfoState = useSelector(state => state.auth.userInfo);
  const { gauss, demo } = userInfoState;
  const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];
    if (
      userInfo !== null &&
      userInfo !== undefined &&
      userInfo.perfil !== null &&
      userInfo.perfil !== undefined &&
      userInfo.perfil.perfilAcceso !== null &&
      userInfo.perfil.perfilAcceso !== undefined
    ) {
      const perfilesAccesos = userInfo.perfil.perfilAcceso;
      const filtrados = perfilesAccesos.filter((pa) => pa.aplicacionKey === 'alertas');

      if (filtrados) filtrados.forEach((fil) => items.push(fil));
    }
    setSecciones(items);
  }, []);

  useEffect(() => {
    if (storageToken())
      dispatch(authCheckState());
  }, [dispatch]);

  useEffect(() => {
    if (!storageToken()) {
      const token = search.slice(1);
      dispatch(login(token));
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (userInfo === null || userInfo === undefined || userInfo.id === null || userInfo.id === undefined) {
      const item = userInfoState && userInfoState.id
        ? userInfoState
        : getUserInfo();
      setUserInfo(item);
    }
  }, [userInfoState, userInfo, setUserInfo]);

  useEffect(() => {
    if (userInfo === null || userInfo === undefined || userInfo.id === null || userInfo.id === undefined) {
      const item = userInfoState && userInfoState
        ? userInfoState
        : getUserInfo();
      setUserInfo(item);
      obtenerSecciones(item);
    }
  }, [userInfoState, userInfo, setUserInfo, obtenerSecciones]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec.key === key);
    return (gauss && demo) || (seccion !== null && seccion !== undefined);
  };

  let routes = (
    <Routes>
      <Route path="/" element={<PantallaCarga />} />
    </Routes>
  );

  if (token !== null && !loadingAuth) {
    routes = (
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route index path="/administracionAlertas" element={<AdministracionAlertas />} />
        <Route index path="/administracionInformes" element={<AdministracionInformes />} />
        {tieneAcceso('demo') && (<Route index path="/pruebaNotificaciones" element={<TestNotificaciones />} />)}
        <Route path="/" element={token ? <Navigate to="/administracionAlertas" /> : <Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <SimpleBackdrop
            open={
              loadingCambiarEmpresa ||
              loadingCambiarMoneda ||
              loadingExecuteCambiarAjustaPorInflacion ||
              loadingCambiarSucursal
            }
          />
          {routes}
        </Layout>
        <IframePortal />
      </Suspense>
    </div>
  );

}

export default App;