export const urlPortal = () => {
  return window.location.replace(process.env.REACT_APP_PORTAL_URL);
};

export const urlPortalLogout = () => {
  return window.location.replace(`${process.env.REACT_APP_PORTAL_URL}/logout`);
}

export const baseUrlVendedores = () => {
  return process.env.REACT_APP_VENDEDORES_URL_BASE;
};

export const baseUrlDashboard = () => {
  return process.env.REACT_APP_DASHBOARD_URL_BASE;
};

export const baseUrlAlertas = () => {
  return process.env.REACT_APP_ALERTAS_URL_BASE;
}