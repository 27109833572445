import React from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch } from 'react-redux';
import { enviarNotificacionAlerta } from "../../../../store/slices/alertasSlice";

const TestAlertas = () => {
  const dispatch = useDispatch();

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionAlerta(4))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="ALARMA DE NOTAS DE CREDITO MAYORES A N IMPORTE - VENTAS"
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Nota de Crédito Ventas
        </Button>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionAlerta(1))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="ALARMA DE NOTAS DE CREDITO MAYORES A N IMPORTE - COMPRAS"
          style={{ paddingInline: '15px', color: 'white', fontSize: 'small' }} >
          Nota de Crédito Compras
        </Button>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionAlerta(2))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="ALARMA COMPROBANTES MAYORES A N IMPORTE - VENTAS"
          style={{ paddingInline: '5px', color: 'white', fontSize: 'small' }} >
          Comprobante Ventas
        </Button>
      </Grid>
      <Grid item xs={3} lg={2}>
        <Button
          onClick={() => dispatch(enviarNotificacionAlerta(3))}
          color='info'
          variant='contained'
          fullWidth={true}
          title="ALARMA COMPROBANTES MAYORES A N IMPORTE - COMPRAS"
          style={{ paddingInline: '5px', color: 'white', fontSize: 'small' }} >
          Comprobante Compras
        </Button>
      </Grid>
    </Grid>
  )
}

export default TestAlertas;