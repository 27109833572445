import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadHistorialEjecucionAlerta } from '../../../../store/slices/alertasSlice';
import Table from '../../../UI/Table/Table';
import { isEven } from '../../../../assets/shared/formatNumber';

const AlertaConfiguradaEjecuciones = (props) => {
  const dispatch = useDispatch();
  const { alerta } = props;
  const estaCargado = useSelector((state) => state.alertas.historialAlerta.estaCargado);
  const loading = useSelector((state) => state.alertas.historialAlerta.loading);
  const registros = useSelector((state) => state.alertas.historialAlerta.registros);
  const tableRef = useRef();

  useEffect(() => {
    if (alerta && (!alerta.historialDeEjecuciones || alerta.historialDeEjecuciones.length === 0) && !estaCargado && !loading)
      dispatch(loadHistorialEjecucionAlerta(alerta.id));
  }, [dispatch, alerta, estaCargado, loading]);

  const headerStyle = {
    fontSize: '14px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: '#fafafa'
  };

  const cellStyle = {
    fontSize: '12px',
    paddingBottom: '0px',
    paddingTop: '0px'
  };

  const columns = [
    {
      title: 'Horario',
      field: 'horaEjecucion',
      align: 'left',
      cellStyle: {
        ...cellStyle,
        width: '150px',
      },
      headerStyle: {
        ...headerStyle,
        width: '20%',
      },
      render: (rowData) => rowData.horarioEjecucion ? new Date(rowData.horarioEjecucion).toLocaleString() : ''
    },
    {
      title: 'Empresa',
      field: 'empresa',
      align: 'left',
      cellStyle: {
        ...cellStyle,
        width: '150px',
      },
      headerStyle,
    },
    {
      title: 'Usuario',
      field: 'usuario',
      align: 'left',
      cellStyle: {
        ...cellStyle,
        width: '150px',
      },
      headerStyle,
    },
    {
      title: 'Query',
      field: 'query',
      align: 'left',
      cellStyle: {
        ...cellStyle,
        width: '750px',
      },
      headerStyle,
    },
  ];

  const data = () => {
    var items = alerta && alerta.historialDeEjecuciones && alerta.historialDeEjecuciones.length > 0
      ? alerta.historialDeEjecuciones : registros;
    return items && items.map((item) => {
      return {
        horarioEjecucion: item.horaEjecucion,
        empresa: alerta && alerta.empresa && alerta.empresa.nombre,
        usuario: item.usuario ? `${item.usuario.nombre} ${item.usuario.apellido}` : '',
        query: item.query
      }
    })
  }

  return (
    <Table
      tableRef={tableRef}
      title=""
      loading={loading}
      columns={columns}
      data={data()}
      noBoxShadow
      style={{ width: '100%' }}
      customOptions={{
        thirdSortClick: false,
        sorting: true,
        grouping: false,
        draggable: false,
        search: false,
        toolbar: false,
        showTitle: false,
        maxBodyHeight: 450,
        paging: true,
        rowStyle: (rowData, index) => ({
          backgroundColor: !isEven(index) && '#f2f2f2'
        })
      }}
      tableKey={"tabla-historia-de-ejecuciones"}
    />
  )
}

export default AlertaConfiguradaEjecuciones;