import React, { useCallback, useEffect, useState } from 'react';

import Menu from '../../UI/Menu/Menu';
import Dialog from '../../UI/Dialog/Dialog';
import ComprobantesCobranzas from '../Comprobantes/ComprobantesCobranzas';
import ComprobantesPedidos from '../Comprobantes/ComprobantesPedidos';
import ComprobantesCompras from '../Comprobantes/ComprobantesCompras';
import ComprobantesGastos from '../Comprobantes/ComprobantesGastos';
import ComprobantesPagos from '../Comprobantes/ComprobantesPagos';
import ComprobantesVentas from '../Comprobantes/ComprobantesVentas';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '../../UI/Button/Button';
import IndicadoresConfig from '../Indicadores/IndicadoresConfig';
import EmpresaSelector from '../../UI/EmpresaSelector/EmpresaSelector';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import classes from './DialogConfiguraciones.module.css';
import { Tooltip, Typography } from '@mui/material';
import IndicadoresMultiempresaConfig from '../IndicadoresMultiempresa/IndicadoresMultiempresaConfig';
import CategoriasItemsDeComprasConfig from '../Categorias/CategoriasItemsDeComprasConfig';
import { storageMultiempresa } from '../../../assets/shared/sessionData';
import CategoriasDeCliente from '../Categorias/CategoriasDeCliente';
import CategoriasDeProveedorConfig from '../Categorias/CategoriasDeProveedorConfig';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SucursalesConfig from '../Sucursales/SucursalesConfig';
import ChequesValorEstado from '../ChequesValorEstado/ChequesValorEstado';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadIndicadores, postComprobantesCobranzas, postComprobantesCompras, postCategoriasDeProveedor,
  postComprobantesGastos, postCategoriasDelCliente, postCategoriasItemsCompra,
  postComprobantesPagos, postComprobantesPedidos, postComprobantesVentas, postIndicadoresOcultos
} from '../../../store/slices/configuracionesSlice';

const MenuConfiguraciones = (props) => {

  //#region Slices
  const dispatch = useDispatch();
  const indicadores = useSelector(state => state.configuraciones.indicadoresPorUsuario.indicadores);
  const loadingPostIndicadores = useSelector(state => state.configuraciones.postIndicadoresOcultos.loading);
  const loadingPostComprobantes = useSelector(state => state.configuraciones.postComprobantes.loading);
  const loadingBodyDialog = useSelector(state =>
    state.configuraciones.comprobantesPedidos.loading ||
    state.configuraciones.comprobantesCobranzas.loading ||
    state.configuraciones.comprobantesCompras.loading ||
    state.configuraciones.comprobantesGastos.loading ||
    state.configuraciones.comprobantesPagos.loading ||
    state.configuraciones.comprobantesVentas.loading ||
    state.configuraciones.indicadoresPorUsuario.loading ||
    state.configuraciones.indicadoresPorUsuario.loading ||
    state.configuraciones.loadCategoriasItemsDeCompras.loading ||
    state.configuraciones.categoriasDelCliente.loading ||
    state.configuraciones.categoriasDeProveedor.loading);

  const onLoadIndicadores = useCallback(() => dispatch(loadIndicadores()), [dispatch]);
  const onPostComprobantesGastos = (empresaId, comprobantes) =>
    dispatch(postComprobantesGastos(empresaId, comprobantes));

  const onPostComprobantesPedidos = (empresaId, comprobantes) =>
    dispatch(postComprobantesPedidos(empresaId, comprobantes));

  const onPostComprobantesCobranzas = (empresaId, comprobantes) =>
    dispatch(postComprobantesCobranzas(empresaId, comprobantes));

  const onPostComprobantesCompras = (empresaId, comprobantes) =>
    dispatch(postComprobantesCompras(empresaId, comprobantes));

  const onPostComprobantesPagos = (empresaId, comprobantes) =>
    dispatch(postComprobantesPagos(empresaId, comprobantes));

  const onPostComprobantesVentas = (empresaId, comprobantes) =>
    dispatch(postComprobantesVentas(empresaId, comprobantes));

  const onPostIndicadores = (indicadores) => dispatch(postIndicadoresOcultos(indicadores));

  const onPostCategoriasItems = (empresaId, categoriasItems) =>
    dispatch(postCategoriasItemsCompra(empresaId, categoriasItems));

  const onPostCategoriasDelCliente = (empresaId, categoriasDelCliente) =>
    dispatch(postCategoriasDelCliente(empresaId, categoriasDelCliente));

  const onPostCategoriasDeProveedor = (empresaId, categoriasDeProveedor) =>
    dispatch(postCategoriasDeProveedor(empresaId, categoriasDeProveedor));
  //#endregion

  const subtitleTypeIndicador = 'Seleccione los indicadores activos';
  const subtitleTypeComprobante = `Seleccione los comprobantes que desea tomar en cuenta para el resultado de los indicadores`;
  const subtitleTypeCategoria = `Seleccione las categorias que desea tomar en cuenta para el resultado de los indicadores`;
  const subtitleTypeSucursal = 'Seleccione una sucursal para editar o cree una nueva sucursal.';

  const listItems = [
    {
      id: 'cobranzas',
      title: 'Comprobantes de cobranzas',
      subtitle: subtitleTypeComprobante,
      type: 'comprobante',
      indicadoresFilter: 'comprobanteCobranzas',
      indicadores: ['indicador1', 'indicador2', 'indicador3']
    },
    {
      id: 'compras',
      title: 'Comprobantes de compras',
      subtitle: subtitleTypeComprobante,
      indicadoresFilter: 'comprobanteCompras',
      type: 'comprobante'
    },
    {
      id: 'gastos',
      title: 'Comprobantes de gastos',
      subtitle: subtitleTypeComprobante,
      indicadoresFilter: 'comprobanteGastos',
      type: 'comprobante'
    },
    {
      id: 'pedidos',
      title: 'Comprobantes de pedidos',
      subtitle: subtitleTypeComprobante,
      indicadoresFilter: 'comprobantePedidos',
      type: 'comprobante'
    },
    {
      id: 'pagos',
      title: 'Comprobantes de pagos',
      subtitle: subtitleTypeComprobante,
      indicadoresFilter: 'comprobantePagos',
      type: 'comprobante'
    },
    {
      id: 'ventas',
      title: 'Comprobantes de ventas',
      subtitle: subtitleTypeComprobante,
      indicadoresFilter: 'comprobanteVentas',
      type: 'comprobante'
    },
    {
      id: 'categoriasItemsDeCompras',
      title: 'Categoría de ítems de compras',
      subtitle: subtitleTypeCategoria,
      indicadoresFilter: 'categoriaItemsDeCompras',
      type: 'categoria'
    },
    {
      id: 'categoriasDeCliente',
      title: 'Saldos por categoría de clientes',
      subtitle: subtitleTypeCategoria,
      indicadoresFilter: 'categoriaSaldosCliente',
      type: 'categoria'
    },
    {
      id: 'categoriasDeProveedor',
      title: 'Saldos por categoría de proveedor',
      subtitle: subtitleTypeCategoria,
      indicadoresFilter: 'categoriaProveedor',
      type: 'categoria'
    },
    {
      id: 'sucursales',
      title: 'Sucursales',
      subtitle: subtitleTypeSucursal,
      type: 'sucursal'
    },
    {
      id: 'indicadores',
      title: 'Indicadores',
      subtitle: subtitleTypeIndicador,
      type: 'indicador'
    },
    {
      id: 'indicadoresMultiempresa',
      title: 'Indicadores multiempresa',
      subtitle: subtitleTypeIndicador,
      type: 'indicador'
    },
    {
      id: 'chequesValorEstado',
      title: 'Cheques terceros',
      type: 'indicador',
      disableTitle: true,
      disableFooter: true
    }
  ];

  const [dialogState, setDialogState] = useState({
    show: false,
    content: '',
    title: '',
    subtitle: '',
    type: '',
    indicadoresFilter: '',
    indicadores: []
  });

  const [comprobantesSeleccionados, setComprobantesSeleccionados] = useState([]);
  const [indicadoresSeleccionados, setIndicadoresSeleccionados] = useState([]);
  const [empresaId, setEmpresaId] = useState(parseInt(sessionStorage.getItem('empresaId'), 10));
  const [dialogCambiarEmpresa, setDialogCambiarEmpresa] = useState({
    show: false,
    empId: null
  });
  const [dialogConfirmCloseShow, setDialogConfirmCloseShow] = useState(false);

  useEffect(() => {
    setComprobantesSeleccionados([]);
    setIndicadoresSeleccionados([]);
    return () => {
      setComprobantesSeleccionados([]);
      setIndicadoresSeleccionados([]);
      setEmpresaId(parseInt(sessionStorage.getItem('empresaId'), 10));
    };
  }, []);

  useEffect(() => {
    if (indicadores && indicadores.length === 0) {
      onLoadIndicadores();
    }
  }, [onLoadIndicadores, indicadores]);

  const showContent = () => {
    switch (dialogState.content) {
      case 'cobranzas':
        return (
          <ComprobantesCobranzas
            configInicial={guardarConfigInicial}
            onChangeValue={(comp) => handleChangeFiltros(comp)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'compras':
        return (
          <ComprobantesCompras
            configInicial={guardarConfigInicial}
            onChangeValue={(comp) => handleChangeFiltros(comp)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'pedidos':
        return (
          <ComprobantesPedidos
            configInicial={guardarConfigInicial}
            onChangeValue={(comprobante) => handleChangeFiltros(comprobante)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'gastos':
        return (
          <ComprobantesGastos
            configInicial={guardarConfigInicial}
            onChangeValue={(comp) => handleChangeFiltros(comp)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'pagos':
        return (
          <ComprobantesPagos
            configInicial={guardarConfigInicial}
            onChangeValue={(comp) => handleChangeFiltros(comp)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'ventas':
        return (
          <ComprobantesVentas
            configInicial={guardarConfigInicial}
            onChangeValue={(comp) => handleChangeFiltros(comp)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'indicadores':
        return (
          <IndicadoresConfig
            configInicial={guardarConfigInicial}
            onChangeValue={handleChangeIndicadores}
            seleccionarTodos={() => onSeleccionarTodosIndicadoresClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosIndicadoresClick(todos)}
          />
        );

      case 'indicadoresMultiempresa':
        return (
          <IndicadoresMultiempresaConfig
            configInicial={guardarConfigInicial}
            onChangeValue={handleChangeIndicadores}
            seleccionarTodos={() => onSeleccionarTodosIndicadoresClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosIndicadoresClick(todos)}
          />
        );

      case 'categoriasItemsDeCompras':
        return (
          <CategoriasItemsDeComprasConfig
            configInicial={guardarConfigInicial}
            onChangeValue={(cat) => handleChangeFiltros(cat)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'categoriasDeCliente':
        return (
          <CategoriasDeCliente
            configInicial={guardarConfigInicial}
            onChangeValue={(cat) => handleChangeFiltros(cat)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'categoriasDeProveedor':
        return (
          <CategoriasDeProveedorConfig
            configInicial={guardarConfigInicial}
            onChangeValue={(cat) => handleChangeFiltros(cat)}
            empresaId={empresaId}
            seleccionarTodos={() => onSeleccionarTodasCategoriasClick()}
            deseleccionarTodos={(todos) => onDeseleccionarTodosClick(todos)}
          />
        );

      case 'sucursales':
        return <SucursalesConfig empresaId={empresaId} />;

      case 'chequesValorEstado':
        return <ChequesValorEstado onCloseModal={() => onConfirmCloseClick()} />;

      default:
        return null;
    }
  };

  const [configChanged, setConfigChanged] = useState(false);

  const onSeleccionarTodosIndicadoresClick = () => {
    setConfigChanged(true);
    indicadoresSeleccionados.splice(0, indicadoresSeleccionados.length);
  };

  const onDeseleccionarTodosIndicadoresClick = (todo) => {
    setConfigChanged(true);
    indicadoresSeleccionados.splice(0, indicadoresSeleccionados.length);
    todo.map((indicador) => {
      return handleChangeIndicadores(indicador);
    });
  };

  const onSeleccionarTodasCategoriasClick = () => {
    setConfigChanged(true);
    comprobantesSeleccionados.splice(0, comprobantesSeleccionados.length);
  };

  const onDeseleccionarTodosClick = (todo) => {
    setConfigChanged(true);
    comprobantesSeleccionados.splice(0, comprobantesSeleccionados.length);
    todo.map((comprobante) => {
      return handleChangeFiltros(comprobante);
    });
  };

  const guardarConfigInicial = (arr) => {
    console.log("configuración inicial valor de arr: ", arr)
    if (dialogState.type === 'indicador') {
      setIndicadoresSeleccionados(arr);
    } else if (!dialogState.type) {
      return;
    } else {
      setComprobantesSeleccionados(arr);
    }
  };

  const handleChangeIndicadores = (value) => {
    const existIndicador = indicadoresSeleccionados.filter((ind) => ind.id === value.id);
    setConfigChanged(true);
    existIndicador.length > 0
      ? removeItemFromArr(indicadoresSeleccionados, existIndicador)
      : indicadoresSeleccionados.push(value);
  };

  const handleChangeFiltros = (value) => {
    const existItem = comprobantesSeleccionados.filter((comp) => comp.eiffelId === value.eiffelId);
    setConfigChanged(true);
    existItem.length > 0
      ? removeItemFromArr(comprobantesSeleccionados, existItem)
      : comprobantesSeleccionados.push(value);
  };

  const removeItemFromArr = (arr, item) => {
    var i = arr.indexOf(item[0]);

    if (i !== -1) {
      arr.splice(i, 1);
    }
  };

  const handleCloseDialog = () => {
    if (!configChanged) {
      onConfirmCloseClick();
    } else {
      setDialogConfirmCloseShow(true);
    }
  };

  const onItemClick = (selectedItem) => {
    const indicadoresFiltrados = indicadores && indicadores
      .filter((ind) => ind[selectedItem.indicadoresFilter] === true)
      .map((indicador) => indicador.nombre);

    setDialogState({
      show: true,
      content: selectedItem.id,
      title: selectedItem.title,
      subtitle: selectedItem.subtitle,
      type: selectedItem.type,
      indicadores: indicadoresFiltrados,
      disableTitle: selectedItem.disableTitle,
      disableFooter: selectedItem.disableFooter
    });
  };

  const onGuardarCambiosClick = () => {
    switch (dialogState.content) {
      case 'cobranzas':
        return onPostComprobantesCobranzas(empresaId, comprobantesSeleccionados);

      case 'compras':
        return onPostComprobantesCompras(empresaId, comprobantesSeleccionados);

      case 'gastos':
        return onPostComprobantesGastos(empresaId, comprobantesSeleccionados);

      case 'pedidos':
        return onPostComprobantesPedidos(empresaId, comprobantesSeleccionados);

      case 'pagos':
        return onPostComprobantesPagos(empresaId, comprobantesSeleccionados);

      case 'ventas':
        return onPostComprobantesVentas(empresaId, comprobantesSeleccionados);

      case 'indicadores':
        return onPostIndicadores(indicadoresSeleccionados);

      case 'indicadoresMultiempresa':
        return onPostIndicadores(indicadoresSeleccionados);

      case 'categoriasItemsDeCompras':
        return onPostCategoriasItems(empresaId, comprobantesSeleccionados);

      case 'categoriasDeCliente':
        return onPostCategoriasDelCliente(empresaId, comprobantesSeleccionados);

      case 'categoriasDeProveedor':
        return onPostCategoriasDeProveedor(empresaId, comprobantesSeleccionados);

      case 'chequesValorEstado':
        return alert('postCheques');

      default:
        return null;
    }
  };

  const handleChangeEmpresa = (e) => {
    setDialogCambiarEmpresa({ show: true, empId: e.target.value });
  };

  const onAceptarCambiarEmpresaClick = () => {
    setEmpresaId(dialogCambiarEmpresa.empId);
    setDialogCambiarEmpresa({ show: false, empId: null });
  };

  const onCancelarCambiarEmpresaClick = () => {
    setDialogCambiarEmpresa({ show: false, empId: null });
  };

  const onConfirmCloseClick = () => {
    setDialogConfirmCloseShow(false);
    setDialogState({
      show: false,
      content: '',
      title: '',
      type: '',
      indicadores: []
    });
    setConfigChanged(false);
  };

  const armarTooltipIndicadores = () => {
    return (
      <div>
        <div>
          Indicadores en los que impacta <br />
          esta configuración:
        </div>
        {dialogState.indicadores.map((indicador, index) => {
          return <div key={index}>{indicador}</div>;
        })}
      </div>
    );
  };

  const enableGuardarCambiosButton = !loadingPostIndicadores && !loadingPostComprobantes;

  return (
    <div>
      <Menu
        title={
          <SettingsIcon
            style={{
              color: 'white',
              cursor: 'pointer',
              borderRadius: '5px',
              fontSize: props.iconSize
            }}
          />
        }
        listItems={listItems}
        onItemClick={(selectedItem) => onItemClick(selectedItem)}
      />
      <Dialog
        title={
          <div classes={classes.titleContent} style={{ width: '100%' }}>
            <div className={classes.titleTexto} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h5">{!dialogState.disableTitle && dialogState.title}</Typography>

              <IconButton aria-label="close" onClick={() => handleCloseDialog()}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.subtitleTexto}>
              <Typography variant="caption">{dialogState.subtitle}</Typography>
            </div>
            <div className={classes.titleEmpresas}>
              {dialogState.type === 'indicador'
                ? undefined
                : storageMultiempresa() === 'true' && <EmpresaSelector onChangeValue={handleChangeEmpresa} />}
            </div>
          </div>
        }
        fullWidth={dialogState.type === 'sucursal'}
        show={dialogState.show}
        body={
          <div
            className={classes.bodyDialog}
            style={loadingBodyDialog ? { display: 'flex', justifyContent: 'center' } : undefined}
          >
            {showContent()}
          </div>
        }
        onClose={handleCloseDialog}
        actions={
          dialogState.type !== 'sucursal' &&
          !dialogState.disableFooter && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              {dialogState.type !== 'indicador' && (
                <Tooltip classes={{ popper: classes.TooltipInfo }} title={armarTooltipIndicadores()}>
                  <InfoOutlinedIcon fontSize="large" style={{ opacity: 0.5 }} />
                </Tooltip>
              )}
              <Button
                disabled={!enableGuardarCambiosButton}
                onClick={() => {
                  onGuardarCambiosClick();
                }}
              >
                Guardar cambios
              </Button>
            </div>
          )
        }
      />
      {dialogCambiarEmpresa.show && (
        <Dialog
          show={dialogCambiarEmpresa.show}
          title={<Typography variant="h5">Atención</Typography>}
          body={`Si cambia de empresa sin guardar los cambios, estos se perderán.`}
          onClose={onCancelarCambiarEmpresaClick}
          actions={
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  onAceptarCambiarEmpresaClick();
                }}
              >
                Aceptar
              </Button>
              <Button
                onClick={() => {
                  onCancelarCambiarEmpresaClick();
                }}
                style={{ backgroundColor: '#ff6256' }}
              >
                Cancelar
              </Button>
            </div>
          }
        />
      )}
      {dialogConfirmCloseShow && (
        <Dialog
          onClose={() => undefined}
          show={dialogConfirmCloseShow}
          title={<Typography variant="h5">Atención</Typography>}
          body={`Si cierra esta ventana sin guardar los cambios, estos se perderán.`}
          actions={
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  onConfirmCloseClick();
                }}
              >
                Aceptar
              </Button>
              <Button
                onClick={() => {
                  setDialogConfirmCloseShow(false);
                }}
                style={{ backgroundColor: '#ff6256' }}
              >
                Cancelar
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default MenuConfiguraciones;