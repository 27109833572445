import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';
import { baseUrlDashboard } from '../../assets/shared/urlApps';
import { enqueueSnackbar } from './snackbarSlice';
import { useDispatch } from 'react-redux';
import { storageEmpresaId, storageUsuarioId } from '../../assets/shared/sessionData';
import idIndicadores from '../../assets/shared/idIndicadores';

export const loadSucursalById = createAsyncThunk(
  'sucursales/loadSucursalById',
  async (payload) => {
    const { sucursalId, success, errorFail } = payload;
    await axios.get(`${baseUrlDashboard()}sucursales/sucursal?sucursalId=${sucursalId}`)
      .then((response) => {
        success(response.data);
      })
      .catch((error) => {
        errorFail(error);
      });
  }
);

export const createSucursal = createAsyncThunk(
  'sucursales/createSucursal',
  async (payload) => {
    const { nuevaSucursal, success } = payload;
    const response = axios.post(`${baseUrlDashboard()}sucursales/sucursal`, nuevaSucursal);
    return { data: response.data, success };
  }
);

export const updateSucursal = createAsyncThunk(
  'sucursales/updateSucursal',
  async (payload) => {
    const { sucursalId, updateSucursal } = payload;
    const response = await axios.put(`${baseUrlDashboard()}sucursales/sucursal?sucursalId=${sucursalId}`, updateSucursal);
    return response.data;
  }
);

export const loadSucursales = createAsyncThunk(
  'sucursales/loadSucursales',
  async (payload) => {
    let { empresaId, incluirInactivas } = payload;

    if (!empresaId)
      empresaId = storageEmpresaId();

    const response = await axios.get(`${baseUrlDashboard()}sucursales/sucursales?empresaId=${empresaId}&incluirInactivas=${incluirInactivas}`);
    return response.data;
  }
);

export const cambiarSucursal = createAsyncThunk(
  'sucursales/cambiarSucursal',
  async (idSucursal) => {
    const idEmpresa = storageEmpresaId();
    const idUsuario = storageUsuarioId();
    await axios.put(`${baseUrlDashboard()}sucursales/cambiarSucursarPorDefecto?empresaId=${idEmpresa}&usuarioId=${idUsuario}&sucursalId=${idSucursal}&indicadorId=${idIndicadores.cambiarSucursal}`, null);
    return idSucursal;
  }
);

const initialState = {
  executeCreateSucursal: false,
  executeUpdateSucursal: false,
  loadingCambiarSucursal: false,
  loadSucursales: {
    sucursales: [],
    loading: false,
    estaCargado: false,
    error: null
  },  
  sucursalSeleccionada: null,
};

export const sucursalesSlice = createSlice({
  name: 'sucursales',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSucursales.pending, (state) => {
        state.loadSucursales.sucursales = [];
        state.loadSucursales.loading = true;
        state.loadSucursales.error = null;
        state.loadSucursales.estaCargado = false;
      })
      .addCase(loadSucursales.fulfilled, (state, response) => {
        state.loadSucursales.sucursales = response.payload;
        state.loadSucursales.loading = false;
        state.loadSucursales.error = null;
        state.loadSucursales.estaCargado = true;
      })
      .addCase(loadSucursales.rejected, (state, error) => {
        state.loadSucursales.loading = false;
        state.loadSucursales.estaCargado = true;
        state.loadSucursales.error = error.response
          ? error.response.data
          : "Se produjo un error conectando con el servidor";
      })
      .addCase(cambiarSucursal.pending, (state) => {
        state.loadingCambiarSucursal = true;
      })
      .addCase(cambiarSucursal.fulfilled, (state, response) => {
        state.loadingCambiarSucursal = false;
        state.sucursalSeleccionada = response.payload;
        window.location.reload();
      })
      .addCase(cambiarSucursal.rejected, (state, error) => {
        state.loadingCambiarSucursal = false;
        const dispatch = useDispatch();
        dispatch(
          enqueueSnackbar({
            message: `Se produjo un error conectando con el servidor`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      })

      .addCase(createSucursal.pending, (state) => {
        state.executeCreateSucursal = true;
      })
      .addCase(createSucursal.fulfilled, (state, action) => {
        const dispatch = useDispatch();
        dispatch(loadSucursales());
        state.executeCreateSucursal = false;

        dispatch(
          enqueueSnackbar({
            message: `Sucursal creada con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );

        return action.success && action.success();
      })
      .addCase(createSucursal.rejected, (state) => {
        state.executeCreateSucursal = false;
        const dispatch = useDispatch();
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      })
      .addCase(updateSucursal.pending, (state) => {
        state.executeUpdateSucursal = true;
      })
      .addCase(updateSucursal.fulfilled, (state) => {
        const dispatch = useDispatch();
        dispatch(
          enqueueSnackbar({
            message: `Sucursal modificada con éxito!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );
        state.executeUpdateSucursal = false;
      })
      .addCase(updateSucursal.rejected, (state) => {
        state.executeUpdateSucursal = false;
        const dispatch = useDispatch();
        dispatch(
          enqueueSnackbar({
            message: `Error al guardar los cambios, intente nuevamente`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      })
  }
});

export default sucursalesSlice.reducer;