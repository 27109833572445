import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../store/slices/authSlice";
import configuracionesReducer from "../store/slices/configuracionesSlice";
import ejecucionPaginasReducer from "../store/slices/ejecucionPaginasSlice";
import modalsReducer from "../store/slices/modalsSlice";
import multiempresaReducer from "../store/slices/multiempresaSlice";
import notificacionesReducer from "../store/slices/notificacionesSlice";
import snackbarReducer from "../store/slices/snackbarSlice";
import sucursalesReducer from "../store/slices/sucursalesSlice";
import monedasReducer from "../store/slices/monedaSlice";
import alertasReducer from "../store/slices/alertasSlice";
import clientesSlice from './slices/clientesSlice';
import tipoAlertasReducer from './slices/tipoAlertaSlice';
import informesReducer from './slices/informesSlice';
import tipoInformeReducer from './slices/tipoInformeSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    configuraciones: configuracionesReducer,
    ejecucionPaginas: ejecucionPaginasReducer,
    modals: modalsReducer,
    monedas: monedasReducer,
    multiempresa: multiempresaReducer,
    notificaciones: notificacionesReducer,
    snackbar: snackbarReducer,
    sucursales: sucursalesReducer,
    alertas: alertasReducer,
    tipoAlertas: tipoAlertasReducer,
    clientes: clientesSlice,
    informes: informesReducer,
    tipoInforme: tipoInformeReducer
  },
});
