import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '../Dialog/Dialog';
import { hiddenEstadoModal } from '../../../store/slices/modalsSlice';

import classes from './ModalEstado.module.css';

export const ModalEstado = () => {
  const dispatch = useDispatch();
  const { show, title, content } = useSelector(state => state.modals.estadoModal);
  const onHiddenModal = () => dispatch(hiddenEstadoModal());

  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h5">{title}</Typography>
            <div>
              <IconButton aria-label="close" onClick={() => onHiddenModal()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
      }
      fullScreen={true}
      show={show}
      body={content}
      onClose={() => onHiddenModal()}
      backgroundColor="#e5e5e5"
    />
  );
};

export default ModalEstado;