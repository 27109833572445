import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: []
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    enqueueSnackbar: {
      reducer: (state, action) => {
        state.notifications.push(action.payload.notification);
      },
      prepare: (notification) => {
        const key = notification.options && notification.options.key;
        notification["key"] = key || new Date().getTime() + Math.random();

        return {
          payload: { notification }
        }
      }
    },
    closeSnackbar: {
      reducer: (state, action) => {
        const dismissAll = !action.payload.key;
        const notifications = [...state.notifications];

        state.notifications = notifications.map((notification) =>
          dismissAll || notification.key === action.payload.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        );
      },
      prepare: (key) => {
        return {
          payload: { key }
        }
      }
    },
    removeSnackbar: {
      reducer: (state, action) => {
        const notifications = [...state.notifications];
        state.notifications = notifications
          .filter((notification) => notification.key !== action.payload.key);
      },
      prepare: (key) => {
        return {
          payload: { key }
        }
      }
    }
  }
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;

export const notifications = state => state.snackbar.notifications;