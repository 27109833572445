import React from "react";
import classes from './TipoAlertas.module.css';
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { HelpOutline, Add } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { openAyudaAlertaDisponibleModal, openConfigurarAlertaDisponibleModal } from "../../../../store/slices/tipoAlertaSlice";

const TipoAlertasCard = (props) => {
  const dispatch = useDispatch();
  const { alerta } = props;
  const puedeConfigurar = alerta && alerta.cantidadConfiguraciones < alerta.cantidadMaxConfiguraciones;

  return (
    <Grid item xs={12} lg={6} container style={{ maxWidth: '95%' }}>
      <Grid item xs={12} container className={classes.ItemCard}>
        <Grid item xs={7} lg={9} container spacing={1} flexDirection='row' justifyContent='center' alignContent='center' >
          <Grid item xs={12}>
            <Typography>
              <strong>Alerta: </strong> <span>{alerta && alerta.tituloGauss}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={3} container spacing={1} flexDirection='row' justifyContent='center' alignContent='center' >
          <Grid item xs={2}>
            <Tooltip title='Ayuda'>
              <IconButton onClick={() => dispatch(openAyudaAlertaDisponibleModal(alerta))}>
                <HelpOutline htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title={puedeConfigurar ? 'Configurar alerta' : 'Se ha alcanzado el número máximo de configuraciones permitidas' }>
              <IconButton onClick={() => puedeConfigurar ? dispatch(openConfigurarAlertaDisponibleModal(alerta)) : undefined }>
                <Add htmlColor='#09d4ff' />
              </IconButton>
            </Tooltip>
          </Grid>   
          <Grid item xs={6} style={{ textAlign: 'center', paddingTop: '15px' }}>
            <Tooltip title="Cant. de alertas configuradas">
              <strong style={{ fontSize: 'large', color: '#515151' }}>{alerta.cantidadConfiguraciones}</strong>
            </Tooltip> / 
            <Tooltip title="Cant. máxima de alertas a configurar">
              <strong style={{ fontSize: 'large', color: '#515151' }}>{alerta.cantidadMaxConfiguraciones}</strong>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TipoAlertasCard;