import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadComprobantesVentas } from '../../../store/slices/configuracionesSlice';
import ComprobantesConfigBody from './ComprobantesConfigBody';

const ComprobantesVentas = (props) => {
  const dispatch = useDispatch();
  const comprobantes = useSelector(state => state.configuraciones.comprobantesVentas.items);
  const loading = useSelector(state => state.configuraciones.comprobantesVentas.loading);
  const onLoad = (empresaId) => dispatch(loadComprobantesVentas(empresaId));
  const {
    empresaId,
    onChangeValue,
    configInicial,
    seleccionarTodos,
    deseleccionarTodos
  } = props;

  return (
    <ComprobantesConfigBody
      comprobantes={comprobantes}
      empresaId={empresaId}
      loading={loading}
      onChangeValue={onChangeValue}
      onLoad={onLoad}
      configInicial={configInicial}
      seleccionarTodos={seleccionarTodos}
      deseleccionarTodos={deseleccionarTodos}
    />
  );
};

export default ComprobantesVentas;