import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';
import { baseUrlDashboard } from '../../assets/shared/urlApps';
import { storageEmpresaId } from '../../assets/shared/sessionData';
import idIndicadores from "../../assets/shared/idIndicadores";

const initialState = {
  monedas: [],
  error: null,
  loading: false,
  estaCargado: false,
  loadingCambiarMoneda: false,
};

export const seleccionarMoneda = createAsyncThunk(
  'monedas/seleccionarMoneda',
  async (idMoneda, idEmpresa = storageEmpresaId()) => {
    const response = await axios.get(`${baseUrlDashboard()}monedas/cambiarMoneda?monedaId=${idMoneda}&empresaId=${idEmpresa}&indicadorId=${idIndicadores.cambiarMoneda}`);
    return response.data;
  }
);

export const loadMonedas = createAsyncThunk(
  'monedas/loadMonedas',
  async (idEmpresa = storageEmpresaId()) => {
    const response = await axios.get(`${baseUrlDashboard()}monedas/monedas?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.monedas}`);
    return response.data;
  }
);

export const monedaSlice = createSlice({
  name: 'monedas',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMonedas.pending, (state) => {
        state.loading = true;
        state.monedas = [];
        state.error = null;
      })
      .addCase(loadMonedas.fulfilled, (state, response) => {
        state.loading = false;
        state.monedas = response.payload;
        state.estaCargado = true;
        state.error = null;
      })
      .addCase(loadMonedas.rejected, (state, error) => {
        state.loading = false;
        state.estaCargado = true;
        state.error = error.response
          ? error.response.data
          : "Se produjo un error conectando con el servidor";
      })
      .addCase(seleccionarMoneda.pending, (state) => {
        state.loadingCambiarMoneda = true;
      })
      .addCase(seleccionarMoneda.fulfilled, (state, response) => {
        window.location.reload();
      })
      .addCase(seleccionarMoneda.rejected, (state, error) => {
        state.loadingCambiarMoneda = false;
        state.error = error.response
          ? error.response.data
          : "Se produjo un error conectando con el servidor";
      })
  }
});

export default monedaSlice.reducer;