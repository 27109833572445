import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken, storageUsuarioId } from "../../assets/shared/sessionData";
import axios from '../../../src/axios-alertas';
import { baseUrlAlertas } from "../../assets/shared/urlApps";

const initialState = {
  informesDisponibles: {
    loading: false,
    estaCargado: false,
    informes: [],
    error: null
  },
  informeDisponibleModal: {
    informe: undefined,
    show: false,
    type: '',
    title: '',
    subTitle: '',
    maxWidth: 'md',
  },
  ejecutarInforme: {
    ejecutando: false,
    ejecutada: false,
    error: null
  },
  configuraciones: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
  tiposUI: {
    loading: false,
    estaCargado: false,
    items: [],
    error: null
  },
};

export const loadInformesDisponibles = createAsyncThunk(
  'tipoInforme/loadInformesDisponibles',
  async () => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const idUsuario = storageUsuarioId();
    const response = await axios.get(`${baseUrlAlertas()}api/tipoInforme?idUsuario=${idUsuario}`, requestOptions);
    return response && response.data;
  }
);

export const ejecutarInforme = createAsyncThunk(
  'tipoInforme/ejecutarInforme',
  async (idInforme, { dispatch }) => {
    const idUsuario = storageUsuarioId();
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/informe/${idInforme}/ejecutar?idUsuario=${idUsuario}`, {}, requestOptions)
      .then(() => dispatch(loadInformesDisponibles()));
  }
);

export const actualizarTipoInforme = createAsyncThunk(
  'tipoInforme/actualizarTipoInforme',
  async ({ informe, onSuccess, onError }, { dispatch }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    await axios.put(`${baseUrlAlertas()}api/tipoInforme/${informe.id}/actualizar`, informe, requestOptions)
      .then(() => {
        dispatch(loadInformesDisponibles());

        if (onSuccess)
          onSuccess();
      }).catch((error) => {
        if (onError)
          onError(error);
      });
  }
);

export const loadTiposUI = createAsyncThunk(
  'tipoInforme/loadTiposUI',
  async () => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(`${baseUrlAlertas()}api/tipoInforme/tiposUI`, requestOptions);
    return response && response.data;
  }
);

export const tipoInformeSlice = createSlice({
  name: 'tipoInforme',
  initialState,
  reducers: {
    openEditarTipoInformeModal: (state, action) => {
      state.informeDisponibleModal.informe = action.payload;
      state.informeDisponibleModal.show = true;
      state.informeDisponibleModal.type = 'update';
      state.informeDisponibleModal.title = 'Editar tipo de informe';
      state.informeDisponibleModal.maxWidth = 'md';
    },
    openAyudaInformeDisponibleModal: (state, action) => {
      state.informeDisponibleModal.informe = action.payload;
      state.informeDisponibleModal.show = true;
      state.informeDisponibleModal.type = 'ayuda';
      state.informeDisponibleModal.title = 'Ayuda';
      state.informeDisponibleModal.maxWidth = 'md';
    },
    openConfigurarInformeDisponibleModal: (state, action) => {
      state.informeDisponibleModal.informe = action.payload;
      state.informeDisponibleModal.show = true;
      state.informeDisponibleModal.type = 'configurar';
      state.informeDisponibleModal.title = 'Configurar informe';
      state.informeDisponibleModal.maxWidth = 'md';
    },
    openActualizarInformeDisponibleModal: (state, action) => {
      state.informeDisponibleModal.informe = action.payload;
      state.informeDisponibleModal.show = true;
      state.informeDisponibleModal.type = 'editar';
      state.informeDisponibleModal.title = 'Editar informe';
      state.informeDisponibleModal.maxWidth = 'md';
    },
    closeInformeDisponibleModal: (state) => {
      state.informeDisponibleModal.informe = undefined;
      state.informeDisponibleModal.show = false;
      state.informeDisponibleModal.type = '';
      state.informeDisponibleModal.title = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadInformesDisponibles.pending, (state) => {
        state.informesDisponibles = {
          loading: true,
          estaCargado: false,
          informes: [],
          error: null,
        };
      })
      .addCase(loadInformesDisponibles.fulfilled, (state, action) => {
        state.informesDisponibles = {
          loading: false,
          estaCargado: true,
          informes: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadInformesDisponibles.rejected, (state, error) => {
        state.informesDisponibles = {
          loading: false,
          estaCargado: true,
          informes: [],
          error: error,
        };
      })
      .addCase(ejecutarInforme.pending, (state) => {
        state.ejecutarInforme = {
          ejecutando: true,
          ejecutada: false,
          error: null,
        };
      })
      .addCase(ejecutarInforme.fulfilled, (state) => {
        state.ejecutarInforme = {
          ejecutando: false,
          ejecutada: true,
          error: null,
        };
      })
      .addCase(ejecutarInforme.rejected, (state, error) => {
        state.ejecutarInforme = {
          ejecutando: false,
          ejecutada: true,
          error: error
        };
      })
      .addCase(loadTiposUI.pending, (state) => {
        state.tiposUI = {
          loading: true,
          estaCargado: false,
          informes: [],
          error: null,
        };
      })
      .addCase(loadTiposUI.fulfilled, (state, action) => {
        state.tiposUI = {
          loading: false,
          estaCargado: true,
          items: action && action.payload && action.payload,
          error: null,
        };
      })
      .addCase(loadTiposUI.rejected, (state, error) => {
        state.tiposUI = {
          loading: false,
          error: error,
          estaCargado: true,
        }
      })
  }
});

export const { openEditarTipoInformeModal, openAyudaInformeDisponibleModal,
  openConfigurarInformeDisponibleModal, openActualizarInformeDisponibleModal, closeInformeDisponibleModal } = tipoInformeSlice.actions;
export default tipoInformeSlice.reducer;