import React, { Fragment } from "react";
import BoxEjecutarPaginaInfo from "../BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo";

const ConteinerWithEjecutarPagina = (props) => {
  const { isExecuted, children } = props;

  return (
    <Fragment>
      {isExecuted ? <BoxEjecutarPaginaInfo /> : children}
    </Fragment>
  );
};
export default ConteinerWithEjecutarPagina;