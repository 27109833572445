import React from "react";
import classes from './TestNotificaciones.module.css';
import TestAlertas from "../../components/UI/Alertas/TestAlertas/TestAlertas";
import { Grid, Typography } from "@mui/material";
import TestInformes from "../../components/UI/Informes/TestInformes/TestInformes";
import NotificacionInterna from "../../components/UI/NotificacionInterna/NotificacionInterna";

const TestNotificaciones = () => {
  return (
    <Grid container spacing={1} className={classes.PanelContainer} justifyContent='flex-start'>
      <Grid item xs={12} style={{ maxHeight: '50px' }}>
        <Typography variant="h5">
          Prueba de Notificaciones
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          Alertas
        </Typography>
        <TestAlertas />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          Informes
        </Typography>
        <TestInformes />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography variant="h6">
          Notificación interna
        </Typography>
        <NotificacionInterna />
      </Grid>
    </Grid>
  )
}

export default TestNotificaciones;