import React, { Fragment, useEffect } from 'react';
import { useNavigationType, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { storageSeccionNombre } from '../../../assets/shared/sessionData';
import { showBackButtonModal } from "../../../store/slices/modalsSlice";
import { cambioPaginaRender } from "../../../store/slices/ejecucionPaginasSlice";

export const BackButton = () => {
  const dispatch = useDispatch();
  const navigationType = useNavigationType();
  const location = useLocation();
   
  useEffect(() => {
    window.onpopstate = e => {
      sessionStorage.setItem('seccionNombre', storageSeccionNombre());
      dispatch(cambioPaginaRender(location.pathname));

      if(navigationType.toUpperCase() === 'PUSH'){
        dispatch(showBackButtonModal(true));      
      }     
    };
  });

  return (
    <Fragment></Fragment>
  );
}

export default BackButton;