import React from "react";
import classes from './TipoAlertas.module.css';
import {  Grid, Typography } from "@mui/material";

const TipoAlertasAyuda = (props) => {
  const { alerta } = props;

  return (
    <Grid container spacing={1} sx={{ minWidth: '550px', minHeight: '150px' }}>
      <Grid item xs={12}>
        <Typography>
          <strong>Alerta: </strong> <span>{alerta && alerta.tituloGauss}</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <strong>Descripción</strong>
        </Typography>
        <Typography className={classes.TextCard}>
          {alerta && alerta.descripcionGauss}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <strong>Query</strong>
        </Typography>
        <Typography className={classes.TextCard}>
          {alerta && alerta.query}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TipoAlertasAyuda;